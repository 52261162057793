import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'primeng/api';
import { ToastModule } from 'primeng/toast';

@Component({
    selector: 'magenty-error-message',
    imports: [CommonModule, SharedModule, ToastModule],
    templateUrl: './error-message.component.html',
    styleUrl: './error-message.component.scss'
})
export class ErrorMessageComponent {}
