import { Route } from '@angular/router';

export const purchaseRouters: Route[] = [
  {
    path: '',
    pathMatch: 'full',
    loadComponent: () =>
      import('./base/base-purchase.component').then(
        (m) => m.BasePurchaseComponent
      ),
  },
  {
    path: 'successfull',
    title: 'Başarılı',
    loadComponent: () =>
      import('./successful/purchase-successfull.component').then(
        (m) => m.PurchaseSuccessfullComponent
      ),
  },
  {
    path: 'faulty',
    title: 'Hata',
    loadComponent: () =>
      import('./faulty/purchase-faulty.component').then(
        (m) => m.PurchaseFaultyComponent
      ),
  },
  {
    path: 'faulty/:fail_message',
    title: 'Hata',
    loadComponent: () =>
      import('./faulty/purchase-faulty.component').then(
        (m) => m.PurchaseFaultyComponent
      ),
  },
];
