import {
  Component,
  effect,
  inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { BadgeModule } from 'primeng/badge';
import { InputTextModule } from 'primeng/inputtext';
import { StyleClassModule } from 'primeng/styleclass';
import { RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { MenuItem, MenuItemCommandEvent } from 'primeng/api';
import { SidebarModule } from 'primeng/sidebar';
import { RadioButtonModule } from 'primeng/radiobutton';
import { InputSwitchModule } from 'primeng/inputswitch';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { PanelSettingsService } from '../../core/services/panel-settings/panel-settings.service';
import { ProviderService } from '../../core/services/provider/provider.service';
import { AuthService } from '../../core/services/auth/auth.service';
import { CategoriesService } from '../../core/services/categories/categories.service';
import { BrandsService } from '../../core/services/brands/brands.service';
import { toSignal } from '@angular/core/rxjs-interop';

import {
  MonitorStatusColorPipe,
  MonitorStatusEnum,
  NotificationTypeColorPipe,
  NotificationTypeEnum,
  NotificationTypePipe,
  OrderStatusColorPipe,
  OrderStatusEnum,
  PicturePipe,
  providerSettingsFunction,
  SearchV2LogicType,
  SearchV2MatchedOptionEnumInterface,
} from '@component-library';
import { StoreService } from '../../core/store/store.service';
import { AvatarModule } from 'primeng/avatar';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { DialogModule } from 'primeng/dialog';
import { PriceTemplateService } from '../../core/services/price-template/price-template.service';
import { PriceTemplateRequestClass } from '../../core/classes/price-template/price-template';
import { ShippingTemplatesService } from '../../core/services/shipping-templates/shipping-templates.service';
import { AccountsService } from '../../core/services/accounts/accounts.service';
import { MainSearchComponent } from './search/main-search.component';
import { OrdersService } from '../../core/services/orders/orders.service';
import packageInfo, { version } from '@packageJson';
import { environment } from '../../../environments/environment';
import { EnvironmentTypeEnum } from '../../core/enums/enums/environment-enum';
import { MenuModule } from 'primeng/menu';

import { Subject, Subscription, takeUntil } from 'rxjs';
import { TabView } from 'primeng/tabview';
import { NotificationsInterface } from '../../core/interfaces/notifications/notifications-interface';
import { NotificationsService } from '../../core/services/appNotifications/notifications.service';
import { NotificationDetailComponent } from '../../components/notifications/notification-detail/notification-detail.component';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ChipModule } from 'primeng/chip';
import { GlobalConfirmationService } from '../../core/services/global-services/global-confirmation-service/global-confirmation.service';
import { Drawer } from 'primeng/drawer';
import { PrimeNG } from 'primeng/config';
import { PrimngTurkishI18n } from '../../../assets/i18n/primeng/primng-turkish-i18n';
import { Tab, TabList, TabPanel, TabPanels, Tabs } from 'primeng/tabs';
import Lara from '@primeng/themes/lara';
import Aura from '@primeng/themes/aura';
import Nora from '@primeng/themes/nora';
import Material from '@primeng/themes/material';
import { ChangeThemeComponent } from './change-theme/change-theme.component';

import { ToggleSwitch } from 'primeng/toggleswitch';
import { DatabaseService } from '../../core/database/database_service';
import { PanelMenu } from 'primeng/panelmenu';
import {
  mainSidebarItemsConst,
  otherSidebarItems,
} from './consts/main-sidebar-items-const';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { TooltipModule } from 'primeng/tooltip';
import { LocalStorageService } from '../../core/services/storage/local-storage.service';
import Clarity from '@microsoft/clarity';
import { TasksSidebarComponent } from '../../components/tasks/tasks-sidebar/tasks-sidebar.component';
import { BlockUI } from 'primeng/blockui';
import * as Sentry from '@sentry/angular';
import { CheckboxModule } from 'primeng/checkbox';
import { OrderListModule } from 'primeng/orderlist';
import { DriverService } from '../../core/services/driver-service/driver.service';
import { AppointmentComponent } from '../../components/appointment/appointment.component';
import { CompanyStatusEnum } from '../../core/enums/company/license-type';

@Component({
  selector: 'magenty-main',
  imports: [
    CommonModule,
    BadgeModule,
    InputTextModule,
    StyleClassModule,
    RouterOutlet,
    RouterLink,
    SidebarModule,
    RadioButtonModule,
    InputSwitchModule,
    FormsModule,
    ButtonModule,
    PicturePipe,
    NgOptimizedImage,
    AvatarModule,
    ScrollPanelModule,
    DialogModule,
    MainSearchComponent,
    MenuModule,
    NotificationTypePipe,
    NotificationTypeColorPipe,
    ChipModule,
    Drawer,
    TabPanels,
    Tabs,
    TabList,
    Tab,
    TabPanel,
    ChangeThemeComponent,
    PanelMenu,
    RouterLinkActive,
    TieredMenuModule,
    TooltipModule,
    OrderStatusColorPipe,
    ToggleSwitch,
    BlockUI,
    TasksSidebarComponent,
    MonitorStatusColorPipe,
    CheckboxModule,
    OrderListModule,
    TabPanel,
  ],
  providers: [DialogService, TabView, PicturePipe],
  templateUrl: './main.component.html',
  styleUrl: './main.component.scss',
})
export class MainComponent implements OnDestroy, OnInit {
  packageInfo = packageInfo;
  private intervalId: any;
  panelSettingService = inject(PanelSettingsService);
  globalConfirmationService = inject(GlobalConfirmationService);
  providersService = inject(ProviderService);
  authService = inject(AuthService);
  accountService = inject(AccountsService);
  priceTemplateService = inject(PriceTemplateService);
  categoriesService = inject(CategoriesService);
  brandsService = inject(BrandsService);
  shippingTemplatesService = inject(ShippingTemplatesService);
  driverService = inject(DriverService);
  storeService = inject(StoreService);
  panelSetting$ = toSignal(
    this.panelSettingService.getPanelSettingsRequest(false)
  );

  activeProviders = toSignal(
    this.providersService.getActiveProvidersRequest(false)
  );
  categories = toSignal(this.categoriesService.getAllCategoriesRequest(false));
  brands = toSignal(this.brandsService.getAllBrandsRequest(false));
  shippimgTemplate = toSignal(
    this.shippingTemplatesService.getAllShippingTemplateRequest(false)
  );
  companyModuleList = toSignal(
    this.accountService.getCompanyModuleMergeList(false)
  );
  company = toSignal(this.accountService.getCompanyByCompanyCode(false));
  priceTemplate = toSignal(
    this.priceTemplateService.priceTemplate(
      new PriceTemplateRequestClass(),
      false
    )
  );
  activeUniqProviderList: any[] = [];

  visibleTopbarMenu = false;
  version = version;
  scale = 14;
  menuRefresh = true;
  orderCounts = {
    newOrder: 0,
    readyToOrder: 0,
    cancellationRefundOrder: 0,
    shippedOrder: 0,
    invoicedOrder: 0,
  };
  isSupportOpen: boolean | undefined;
  items: MenuItem[] | undefined;
  panelSettings = this.storeService.store$()?.panelSettings.base;
  mainsSidebarItems: MenuItem[] = [...mainSidebarItemsConst];
  otherSidebarItems: MenuItem[] = [...otherSidebarItems];
  favoriteSidebarItems: MenuItem[] = [];
  private destroy$ = new Subject<void>();
  expandedMenu: number | null = null;
  sidebarVisible = false;
  menuVisible = false;
  notifications: NotificationsInterface[] = [];
  criticalNotifications: NotificationsInterface[] = [];
  searchv2$!: Subscription;
  notificationDetail: DynamicDialogRef | undefined;
  appointmentDetail: DynamicDialogRef | undefined;
  buyboxCompany: any = undefined;
  notificationCount = {
    all: 0,
    critical: 0,
  };

  isSidebarOpen = false;

  isSidebarCollapsed = false;

  mainTieredMenuItems: MenuItem[] = [];
  otherTieredMenuItems: MenuItem[] = [];

  isMobile = false;
  tasksSidebarVisible = false;
  inProcessTasksCount = 0;

  @ViewChild(ChangeThemeComponent) changeThemeComponent!: ChangeThemeComponent;

  constructor(
    private config: PrimeNG,
    private orderService: OrdersService,
    private notificationsService: NotificationsService,
    private dialogService: DialogService,
    private dbService: DatabaseService,
    private localStorageService: LocalStorageService,
    private picturePipe: PicturePipe
  ) {
    this.config.setTranslation(PrimngTurkishI18n);
    this.getSupportConfig();

    effect(() => {
      this.activeUniqProviderList = [];

      this.storeService.store$()?.providers.activeProviderWithName.map((f) => {
        const providerSettings = providerSettingsFunction(+f.identity);
        const found = this.activeUniqProviderList.find(
          (uniqueProvider) => uniqueProvider.identity === f.identity
        );
        if (!found) {
          this.activeUniqProviderList.push({
            header: providerSettings.title,
            icon: providerSettings.icon,
            image: providerSettings.img,
            providerId: f.id,
            storeName: f.storeName,
            identity: f.identity,
          });
        }
      });
      this.sidebarFill();
      if (
        this.storeService.store$()?.panelSettings?.base?.BuyboxSetting
          ?.UseBuyboxAnaliz === 1 &&
        this.buyboxCompany === undefined
      ) {
        this.buyboxCompany = true;
        this.getBuyboxCompany();
      }
    });

    effect(() => {
      this.panelSettings = this.storeService.store$()?.panelSettings.base;
      this.itemsFill();
    });

    effect(() => {
      if (this.orderService.refleshOrderCounts() > 0) {
        this.refreshOrderCounts();
      }
    });
    this.refreshOrderCounts();
    this.intervalId = setInterval(() => {
      this.refreshOrderCounts();
    }, 5 * 60000);

    this.getNotification(false);
    this.getNotification(true);
    const localIsSidebarCollapsed =
      this.localStorageService.getItem('isSidebarCollapsed');
    if (
      localIsSidebarCollapsed !== undefined &&
      localIsSidebarCollapsed !== null
    ) {
      this.isSidebarCollapsed = localIsSidebarCollapsed;
    } else {
      this.dbService.getDB('isSidebarCollapsed').then((f: boolean) => {
        if (f !== undefined) {
          this.isSidebarCollapsed = f;
        }
      });
    }

    effect(() => {
      if (this.storeService.company$()) {
        if (environment.chatBot) {
          const company = this.storeService.store$().panelSettings.base;

          if (company) {
            Clarity.identify(
              company.Id,
              company.CompanyDetail.Email,
              company?.CompanyDetail.Title,
              `${company.CompanyDetail.Name}, ${company.CompanyDetail.Surname}`
            );

            Sentry.setUser({
              id: company.Id,
              email: company.CompanyDetail.Email,
              username: `${company.CompanyDetail.Name} ${company.CompanyDetail.Surname}`,
              phone: company.CompanyDetail.Phone,
            });

            Sentry.setContext('company', {
              title: company.CompanyDetail.Title,
              companyId: company.Id,
              imageUrl: this.picturePipe.transform(
                company.CompanyDetail.ImageUrl ?? ''
              ),
              version: version,
            });

            const t = window as any;
            t.InfosetChat('boot', {
              widget: {
                apiKey: environment.chatBotApi,
                alignment: 'right',
                horizontalPadding: 15,
                verticalPadding: 50,
              },
              visitor: {
                id: company?.Id,
                firstName: company?.CompanyDetail.Name,
                lastName: company?.CompanyDetail.Surname,
                email: company?.CompanyDetail.Email,
                phone: company?.CompanyDetail.Phone,
                photoUrl: this.picturePipe.transform(
                  company?.CompanyDetail?.ImageUrl ?? ''
                ),

                company: company?.CompanyDetail.Title,
              },
            });
          }
        }
      }
    });

    this.checkScreenSize();
    window.addEventListener('resize', () => {
      this.checkScreenSize();
    });
  }

  ngOnInit() {
    this.menuRefresh = false;
    this.dbService.getDB('sidebarConfig').then((f: any) => {
      if (f) {
        const sidebarConfig: {
          mainsSidebarItems: MenuItem[];
          otherSidebarItems: MenuItem[];
          favoriteSidebarItems: MenuItem[];
        } = f;

        this.favoriteSidebarItems = sidebarConfig.favoriteSidebarItems ?? [];
        this.mainsSidebarItems = sidebarConfig.mainsSidebarItems ?? [
          ...mainSidebarItemsConst,
        ];
        this.otherSidebarItems = sidebarConfig.otherSidebarItems ?? [
          ...otherSidebarItems,
        ];
      }

      setTimeout(() => {
        this.menuRefresh = true;
      }, 200);
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
    window.removeEventListener('resize', () => {
      this.checkScreenSize();
    });
  }

  ngAfterViewInit() {
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.addedNodes.length) {
          const elementimiz = document.getElementById('infoset-btn-frame');
          if (elementimiz) {
            this.toggleSupport();

            observer.disconnect();
          }
        }
      });
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });
  }

  getBuyboxCompany = () => {
    this.panelSettingService.getBuyboxCompany().subscribe((company) => {
      this.buyboxCompany = company;
    });
  };
  async getSupportConfig() {
    await this.dbService.getDB('supportConfig').then((f: boolean) => {
      if (f !== undefined) {
        this.isSupportOpen = f;
      } else {
        if (this.isMobile) {
          this.isSupportOpen = false;
        } else {
          this.isSupportOpen = true;
        }
      }
    });
  }

  async setSupportConfig() {
    await this.dbService.saveDB('supportConfig', this.isSupportOpen);
  }

  toggleSupport(): void {
    const infosetBtnFrame = document.getElementById('infoset-btn-frame');
    if (infosetBtnFrame) {
      infosetBtnFrame.style.display = this.isSupportOpen ? 'block' : 'none';
    }
    this.setSupportConfig();
  }

  sidebarFill = () => {
    // mainsSidebarItems'ı tekrar set etmeye gerek yok, zaten effect içinde yapılıyor
    // this.mainsSidebarItems = [...mainSidebarItemsConst];
    // this.otherSidebarItems = [...otherSidebarItems];

    // Sadece menü durumlarını güncelle

    const foundedSidebarItems = this.mainsSidebarItems.find(
      (f) => f.label === 'Pazaryeri'
    );

    if (foundedSidebarItems && foundedSidebarItems.items) {
      foundedSidebarItems.items = [];
      this.activeUniqProviderList.map((f) => {
        if (foundedSidebarItems.items) {
          foundedSidebarItems.items.push({
            label: f.header,
            icon: f.icon,
            url: `/marketplaces/dashboard/${f.providerId}`,
          });
        }
      });
    }
  };

  refreshOrderCounts() {
    this.orderService
      .countAll()
      .pipe(takeUntil(this.destroy$))
      .subscribe((counts) => {
        this.orderCounts.cancellationRefundOrder = 0;
        counts.Items.forEach((item) => {
          if (item.Status === OrderStatusEnum.New) {
            this.orderCounts.newOrder = item.Count ?? 0;
          } else if (item.Status === OrderStatusEnum.Ready) {
            this.orderCounts.readyToOrder = item.Count ?? 0;
          } else if (item.Status === OrderStatusEnum.Shipped) {
            this.orderCounts.shippedOrder = item.Count ?? 0;
          } else if (item.Status === OrderStatusEnum.Invoiced) {
            this.orderCounts.invoicedOrder = item.Count ?? 0;
          } else if (
            item.Status === OrderStatusEnum.Returned ||
            item.Status === OrderStatusEnum.Canceled
          ) {
            this.orderCounts.cancellationRefundOrder += item.Count ?? 0;
          }
        });

        this.sidebarFill();
      });
  }

  itemsFill = () => {
    const token = this.authService.token$();
    this.items = [
      {
        label: `
            ${this.panelSettings?.CompanyDetail?.Name}
                        ${this.panelSettings?.CompanyDetail?.Surname}`,
        items: [
          {
            label: 'Profil',
            escape: false,
            icon: 'pi pi-user',
            routerLink: 'profile',
          },
          {
            label: 'Eski Arayüz',
            escape: false,
            icon: 'pi pi-inbox',
            url: `http://oldv2.magenty.com/authentication/signin/${token}`,
            target: '_blank',
          },

          {
            label: 'Bildirimler',
            escape: false,
            styleClass: 'lg:hidden',
            icon: 'pi pi-power-off',
          },
          {
            label: 'Görevler',
            escape: false,
            styleClass: 'lg:hidden',
            icon: 'pi pi-pen-to-square',
          },
          {
            label: 'Tema',
            escape: false,
            styleClass: 'lg:hidden',
            icon: 'pi pi-power-off',
          },
          {
            label: 'Versiyon',
            styleClass: 'lg:hidden',
            escape: false,
            icon: 'pi pi-power-off',
          },
          {
            label: 'Çıkış',
            escape: false,
            icon: 'pi pi-power-off',
            command: (event: MenuItemCommandEvent) => {
              this.authService.logout(true);
            },
          },
        ],
      },
    ];
  };

  getNotification = (isEqual: boolean) => {
    const matchedOption = isEqual
      ? SearchV2MatchedOptionEnumInterface[
          SearchV2MatchedOptionEnumInterface.equal
        ]
      : SearchV2MatchedOptionEnumInterface[
          SearchV2MatchedOptionEnumInterface.notEqual
        ];

    this.searchv2$ = this.notificationsService
      .searchv2({
        AscDescKey: 'CreatedOn',
        AscDescType: -1,
        DisplayColumns: [],
        Filters: [
          {
            Key: 'Type',
            LogicType: SearchV2LogicType[SearchV2LogicType.and],
            MatchedOption: matchedOption,
            Value: NotificationTypeEnum.CriticalNotification,
            Value1: '',
            Id: 'Type',
          },
          {
            Key: 'IsRead',
            MatchedOption:
              SearchV2MatchedOptionEnumInterface[
                SearchV2MatchedOptionEnumInterface.equal
              ],
            LogicType: SearchV2LogicType[SearchV2LogicType.and],
            Value: false,
            Value1: '',
            Id: 'IsRead',
          },
        ],
        LogicType: SearchV2LogicType[SearchV2LogicType.and],
        Page: 0,
        PageSize: 50,
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe((f) => {
        if (f.Status) {
          if (!isEqual) {
            this.notifications = f.Items;
            this.notificationCount.all = f.TotalCount;
          } else {
            this.criticalNotifications = f.Items;
            this.notificationCount.critical = f.TotalCount;
          }
        }
      });
  };

  notificationDetailDialog = (id: string, type: NotificationTypeEnum) => {
    this.notificationDetail = this.dialogService.open(
      NotificationDetailComponent,
      {
        header: `Bildirim Detayı`,
        width: '60vw',
        modal: true,
        closable: true,
        data: {
          id,
        },
        breakpoints: {
          '960px': '70vw',
          '640px': '90vw',
        },
      }
    );
    this.notificationDetail.onClose
      .pipe(takeUntil(this.destroy$))
      .subscribe((f) => {
        if (type === NotificationTypeEnum.CriticalNotification) {
          this.getNotification(true);
        } else {
          this.getNotification(false);
        }
      });
  };

  bulkNotificationReading = () => {
    this.globalConfirmationService.questionConfirmation(
      'Tüm Bildirimleri Oku',
      'Tüm bildirimleri okunmuş olarak işaretlemek ister misiniz?',
      () => {
        this.notificationsService
          .bulkRead()
          .pipe(takeUntil(this.destroy$))
          .subscribe((f) => {
            if (f && f.Status) {
              this.getNotification(false);
              this.getNotification(true);
            }
          });
      }
    );
  };

  toggleSidebarCollapse() {
    this.isSidebarCollapsed = !this.isSidebarCollapsed;
    this.localStorageService.setItem(
      'isSidebarCollapsed',
      this.isSidebarCollapsed
    );
    this.dbService.saveDB('isSidebarCollapsed', this.isSidebarCollapsed);
  }

  private checkScreenSize() {
    this.isMobile = window.innerWidth <= 768; // 768px mobil breakpoint olarak kullanılıyor
  }

  openAppointmentModal = () => {
    this.appointmentDetail = this.dialogService.open(AppointmentComponent, {
      header: `Ücretsiz Randevu Oluştur`,
      width: '50vw',
      modal: true,
      closable: true,

      breakpoints: {
        '960px': '50vw',
        '640px': '90vw',
      },
    });
    this.appointmentDetail.onClose
      .pipe(takeUntil(this.destroy$))
      .subscribe((f) => {});
  };

  protected readonly MonitorStatusEnum = MonitorStatusEnum;
  protected readonly environment = environment;
  protected readonly EnvironmentTypeEnum = EnvironmentTypeEnum;
  protected readonly Lara = Lara;
  protected readonly Aura = Aura;
  protected readonly Material = Material;
  protected readonly Nora = Nora;
  protected readonly OrderStatusEnum = OrderStatusEnum;
  protected readonly CompanyStatusEnum = CompanyStatusEnum;
}
