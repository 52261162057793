import { Route } from '@angular/router';

export const profileRouter: Route[] = [
  {
    path: '',
    pathMatch: 'full',
    data: { activeLeft: 0, activeBody: 0 },
    title: 'Temel Ayarlar',
    loadComponent: () =>
      import('./settings/profile-settings.component').then(
        (m) => m.ProfileSettingsComponent
      ),
  },
  {
    path: 'company',
    data: { activeLeft: 1, activeBody: 0 },
    title: 'Firma Ayarları',
    loadComponent: () =>
      import('./company/profile-company.component').then(
        (m) => m.ProfileCompanyComponent
      ),
  },
  {
    path: 'add-user',
    data: { activeLeft: 1, activeBody: 0 },
    title: 'Kullanıcı Ekle',
    loadComponent: () =>
      import('./add-sub-user/profile-add-sub-user.component').then(
        (m) => m.ProfileAddSubUserComponent
      ),
  },
  {
    path: 'module-selection',
    data: { activeLeft: 0, activeBody: 1 },
    title: 'Modül Seçimi',
    loadComponent: () =>
      import('./company-module-merge/company-module-merge.component').then(
        (m) => m.CompanyModuleMergeComponent
      ),
  },
];
