import { MenuItem } from 'primeng/api';
import { MonitorStatusEnum, OrderStatusEnum } from '@component-library';

export const mainSidebarItemsConst: MenuItem[] = [
  {

    label: 'Anasayfa',
    icon: 'pi pi-home',
    url: '/dashboard',
  },
  {
    label: 'Katalog',
    icon: 'pi pi-box',

    items: [
      {
        label: 'Ürünler',
        icon: 'pi pi-minus',
        url: '/catalog/products',
      },
      {
        label: 'Kategoriler',
        icon: 'pi pi-minus',
        url: '/catalog/categories',
      },
      {
        label: 'Markalar',
        icon: 'pi pi-minus',
        url: '/catalog/brands',
      },
      {
        label: 'Varyantlar',
        icon: 'pi pi-minus',
        items: [
          {
            label: 'Varyant Listesi',
            icon: 'pi pi-minus',
            url: '/catalog/variants',
          },
          {
            label: 'Varyant Şablonları',
            icon: 'pi pi-minus',
            url: '/catalog/variant-templates',
          },
          {
            label: 'Ürün Varyant Listesi',
            icon: 'pi pi-minus',
            url: '/catalog/product-variants-list',
          },
        ],
      },
      {
        label: 'Aktarım',
        icon: 'pi pi-minus',
        items: [
          {
            label: 'Excel - XML Aktarım',
            icon: 'pi pi-minus',
            url: '/catalog/transfer',
          },
          {
            label: 'Excel Şablonları',
            icon: 'pi pi-minus',
            url: '/catalog/transfer/excel-template',
          },
        ],
      },
    ],
  },
  {
    label: 'Satışlar',
    icon: 'pi pi-wallet',
    items: [
      {
        label: 'Yeni',
        icon: 'pi pi-minus',
        url: '/sales/new-sales',
        badgeStyleClass: 'saleCount',
        badge: OrderStatusEnum.New.toString(),
      },
      {
        label: 'Hazırlanıyor',
        icon: 'pi pi-minus',
        url: '/sales/ready-sales',
        badgeStyleClass: 'saleCount',
        badge: OrderStatusEnum.Ready.toString(),
      },
      {
        label: 'Kargolandı',
        icon: 'pi pi-minus',
        url: '/sales/shipped-sales',
        badgeStyleClass: 'saleCount',
        badge: OrderStatusEnum.Shipped.toString(),
      },
      {
        label: 'İptal - İade',
        icon: 'pi pi-minus',
        url: '/sales/cancellation-refund-sales',
        badgeStyleClass: 'saleCount',
        badge: OrderStatusEnum.Canceled.toString(),
      },
      {
        label: 'Hepsi',
        icon: 'pi pi-minus',
        items: [
          {
            label: 'Tüm Siparişler',
            icon: 'pi pi-minus',
            url: '/sales/all-sales',
          },
          {
            label: 'Sipariş Hareketleri',
            icon: 'pi pi-minus',
            url: '/sales/order-transactions',
          },
        ],
      },
      {
        label: 'Fatura',
        icon: 'pi pi-minus',
        url: '/sales/invoice-sales',
        badgeStyleClass: 'saleCount',
        badge: OrderStatusEnum.Invoiced.toString(),
      },
    ],
  },
  {
    label: 'Pazaryeri',
    icon: 'pi pi-shopping-cart',
    styleClass: 'max-h-[10rem]',
    id: 'pazaryeri',

    items: [],
  },
  {
    label: 'Raporlar',
    icon: 'pi pi-file-pdf',
    items: [
      {
        label: 'Genel Rapor',
        icon: 'pi pi-minus',
        url: '/reports/main',
      },
      {
        label: 'Ürün Raporu',
        icon: 'pi pi-minus',
        url: '/reports/product',
      },
    ],
  },
  {
    label: 'Görevler',
    icon: 'pi pi-briefcase',
    items: [
      {
        label: 'İşlemler',
        icon: 'pi pi-minus',
        badgeStyleClass: 'tasksCount',
        badge: MonitorStatusEnum.InProcess.toString(),
        url: '/tasks/main',
      },
      {
        label: 'Hatalar',
        icon: 'pi pi-minus',
        url: '/tasks/errors',
      },
    ],
  },
];

export const otherSidebarItems = [
  {
    label: 'Genel',
    escape: false,
    expanded: true,
    id: 'genel',
    items: [
      {
        label: 'Entegrasyon Tanımları',
        icon: 'pi pi-sliders-v',
        items: [
          {
            label: 'Pazaryeri',
            icon: 'pi pi-minus',
            url: '/definitions/marketplace',
          },
          {
            label: 'E-Ticaret',
            icon: 'pi pi-minus',
            url: '/definitions/e-commerce',
          },
          {
            label: 'E-İhracat',
            icon: 'pi pi-minus',
            url: '/definitions/e-exportation',
          },
          {
            label: 'E-fatura',
            icon: 'pi pi-minus',
            url: '/definitions/e-invoice',
          },
          {
            label: 'Kargo',
            icon: 'pi pi-minus',
            url: '/definitions/shipping',
          },
        ],
      },
      {
        label: 'Blog',
        icon: 'pi pi-folder',
        url: '/blog',
      },
      {
        label: 'Dökümanlar',
        icon: 'pi pi-book',
        url: '/documentation',
      },
      {
        label: 'Panel Ayarları',
        icon: 'pi pi-cog',
        url: '/panel-settings',
      },
    ],
  },
];
