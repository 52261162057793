import { Route } from '@angular/router';

export const definitionsRouters: Route[] = [
  {
    path: '',
    pathMatch: 'full',
    title: 'Pazaryeri',
    redirectTo: 'marketplace',
  },
  {
    path: 'marketplace',
    title: 'Pazaryerleri',
    loadComponent: () =>
      import('./marketplace/definitions-marketplace.component').then(
        (m) => m.DefinitionsMarketplaceComponent
      ),
  },
  {
    path: 'e-commerce',
    title: 'E-ticaret',
    loadComponent: () =>
      import('./e-commerce/definitions-e-commerce.component').then(
        (m) => m.DefinitionsECommerceComponent
      ),
  },
  {
    path: 'e-exportation',
    title: 'E-ihracat',
    loadComponent: () =>
      import('./e-exportation/definitions-e-exportation.component').then(
        (m) => m.DefinitionsEExportationComponent
      ),
  },
  {
    path: 'e-invoice',
    title: 'E-Fatura',
    loadComponent: () =>
      import('./e-invoice/definitions-e-invoice.component').then(
        (m) => m.DefinitionsEInvoiceComponent
      ),
  },
  {
    path: 'shipping',
    title: 'Kargo',
    loadComponent: () =>
      import('./shipping/definitions-shipping.component').then(
        (m) => m.DefinitionsShippingComponent
      ),
  },
];
