<i class="pi pi-search text-surface-500 dark:text-surface-300 mt-1 md:hidden" (click)="visible5=true"></i>
<section class="hidden md:block md:w-[30rem] border border-surface rounded-lg">
  <p-inputgroup>
    <p-inputgroup-addon>
      <i class="pi pi-search text-surface-500 dark:text-surface-300"></i>
    </p-inputgroup-addon>
    <input
      type="text" pInputText placeholder="Arama..." class="w-full border-0 shadow-none outline-0"
      (click)="visible5 = true" />
    <p-inputgroup-addon>
      <span class="border p-1 border-surface rounded-border bg-surface-100 dark:bg-surface-800 mr-2 text-surface-600 dark:text-surface-200 text-xs hidden md:block">⌘K</span>
    </p-inputgroup-addon>
  </p-inputgroup>
</section>

<p-dialog
  [(visible)]="visible5" [dismissableMask]="true" [modal]="true" [showHeader]="false"
  [breakpoints]="{ '960px': '75vw', '640px': '100vw' }" [style]="{ width: '50vw', 'max-width': '750px' }"
  contentStyleClass="rounded-t p-0" appendTo="body" (onShow)="input3.focus()"
  (onHide)="visible5 = false; value5 = ''">
  <div class="flex w-full bg-surface-0 dark:bg-surface-800 items-center justify-between px-1">
    <p-inputgroup>
      <p-inputgroup-addon>
        <i class="pi pi-search text-surface-500 dark:text-surface-300"></i>
      </p-inputgroup-addon>
      <input
        type="text" #input3 pInputText placeholder="Arama..." [(ngModel)]="value5"
        (ngModelChange)="search($event)"
        class="w-full border-0 shadow-none outline-0" />
    </p-inputgroup>
    <span class="border p-1 border-surface rounded-border bg-surface-100 dark:bg-surface-800 mr-2 text-surface-600 dark:text-surface-200 text-xs hidden md:block">⌘K</span>
  </div>

  @if (value5.length > 2 && searchedItems.length > 0) {
    <p-scrollPanel [style]="{ width: '100%','max-height': '400px' }">
      <div class="border-y border-surface p-4">
        @for (category of getUniqueCategories(); track $index) {
          <div class="mb-4">
            <h2 class="font-semibold text-xs text-surface-500 dark:text-surface-300 mb-2 mt-0 px-1">{{category}}</h2>
            <ul class="list-none m-0 p-0">
              @for (searchItem of getItemsByCategory(category); track $index) {
                <li [routerLink]="searchItem.url"
                    (click)="visible5=false"
                    class="select-none p-3 font-medium text-base cursor-pointer rounded-lg hover:bg-emphasis hover:text-primary">
                  <div class="flex items-center gap-3">
                    <i [class]="searchItem.icon + ' text-lg text-surface-600 dark:text-surface-400'"></i>
                    <div class="flex flex-col">
                      <span class="text-base">{{searchItem.header}}</span>
                      <span class="text-sm text-surface-500 dark:text-surface-400">{{searchItem.description}}</span>
                    </div>
                  </div>
                </li>
              }
            </ul>
          </div>
        }
      </div>
    </p-scrollPanel>
  }

  @if (value5.length > 2 && searchedItems.length === 0) {
    <div class="p-4 text-center">
      <i class="pi pi-search-minus text-4xl text-surface-300 dark:text-surface-600 mb-3"></i>
      <p class="text-surface-600 dark:text-surface-400">Sonuç bulunamadı</p>
    </div>
  }
</p-dialog>
