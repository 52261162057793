import { Component, HostListener } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';
import { PaginatorModule } from 'primeng/paginator';
import { RouterLink } from '@angular/router';
import { StyleClassModule } from 'primeng/styleclass';
import { TuiFilterPipeModule } from '@taiga-ui/cdk';
import { StoreService } from '../../../core/store/store.service';
import { providerSettingsFunction } from '@component-library';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { searchItemsConsts } from './consts/search-items-consts';
import { SearchItemsInterface } from './interfaces/search-items-interface';
import { activeProviderWithNameInterface } from '../../../core/interfaces/store/store-interface';
import { FormsModule } from '@angular/forms';
import { InputGroup } from 'primeng/inputgroup';
import { InputGroupAddon } from 'primeng/inputgroupaddon';
import Fuse from 'fuse.js';

@Component({
  selector: 'magenty-main-search',
  imports: [
    CommonModule,
    DialogModule,
    InputTextModule,
    PaginatorModule,
    RouterLink,
    StyleClassModule,
    TuiFilterPipeModule,
    ScrollPanelModule,
    FormsModule,
    InputGroup,
    InputGroupAddon,
  ],
  templateUrl: './main-search.component.html',
  styleUrl: './main-search.component.scss',
})
export class MainSearchComponent {
  private fuse: Fuse<SearchItemsInterface>;
  visible5 = false;
  value5 = '';
  searchItems: SearchItemsInterface[] = searchItemsConsts;
  searchedItems: SearchItemsInterface[] = [];

  constructor(private storeService: StoreService) {
    // Initialize Fuse.js with options
    const fuseOptions = {
      keys: ['header', 'searchTerms'],
      threshold: 0.3,
      includeScore: true,
    };
    this.fuse = new Fuse(this.searchItems, fuseOptions);

    this.storeService
      .store$()
      ?.providers?.activeProviderWithName.map((provider) => {
        this.pushSearchItems(provider);
      });
  }

  // Replace the old matcher with Fuse.js search
  readonly matcher = (item: SearchItemsInterface, search: string): boolean => {
    if (!search.trim()) return true;
    const results = this.fuse.search(search);
    const sortedResults = results.sort(
      (a, b) => (b.score ?? 1) - (a.score ?? 1)
    );
    return sortedResults.some((result) => result.item === item);
  };

  search = (event: string) => {
    const results = this.fuse.search(event.trim());
    const sortedResults = results.sort(
      (a, b) => (b.score ?? 1) - (a.score ?? 1)
    );
    this.searchedItems = sortedResults.map((result) => result.item);
  };

  pushSearchItems = (
    activeProviderWithName: activeProviderWithNameInterface
  ) => {
    const providerSettings = providerSettingsFunction(
      +activeProviderWithName.identity
    );

    this.searchItems.push(
      {
        header: `${providerSettings.title} Pazaryeri  ${activeProviderWithName.storeName}`,
        searchTerms: `${providerSettings.title} pazaryeri, ${activeProviderWithName.storeName}, Ürünler , İşlem Halindekiler , Hatalı Ürünler , Stok activeProviderWithNameiyat Senkronizasyon , Güncelle , Başlat , Durdur, Pazaryerinden Toplu Güncelle , Kategori ve Özellik Güncelle , Kargo Şablonu Güncelle , Ürünleri Sil , Detay Güncelleme`,
        url: `/marketplaces/dashboard/${activeProviderWithName.id}`,
        category: 'Pazaryeri',
      },

      {
        header: `${providerSettings.title} Pazaryeri > Fiyat İşlemleri ${activeProviderWithName.storeName}`,
        searchTerms: `${providerSettings.title} pazaryeri, ${activeProviderWithName.storeName},Fiyat Kaynağını Değiştir , Fiyat Değişikliklerini Uygula`,
        url: `/marketplaces/price-transactions/${activeProviderWithName.id}`,
        category: 'Fiyat İşlemleri',
      },
      {
        header: `${providerSettings.title} Pazaryeri > Fiyat Şablonu ${activeProviderWithName.storeName}`,
        searchTerms: `${providerSettings.title} pazaryeri, ${activeProviderWithName.storeName},Fiyat Şablonu , Fiyat Şablonu Ekle , Kaynak Satış Fiyatı, Fiyat Kuralı , Fiyat Kuralı Ekle, Fiyat Açıklaması, Miktar Artışı`,
        url: ` /marketplaces/price-template/${activeProviderWithName.id}`,
        category: 'Fiyat Şablonu',
      },
      {
        header: `${providerSettings.title} Pazaryeri > Toplu İşlemler ${activeProviderWithName.storeName}`,
        searchTerms: `${providerSettings.title} pazaryeri, ${activeProviderWithName.storeName},Toplu Ürün Açma , Yeni Listeleme Yap`,
        url: ` /marketplaces/bulk-transactions/${activeProviderWithName.id}`,
        category: 'Toplu İşlemler',
      },
      {
        header: `${providerSettings.title} Pazaryeri > Kargo Şablonu ${activeProviderWithName.storeName}`,
        searchTerms: `${providerSettings.title} pazaryeri, ${activeProviderWithName.storeName},Yeni Kargo Şablonu , Kargo Şablonu Ekle, Şablon Adı, Şablon Alt Başlık, Teslimat Tipi, Kargo Fiyatı Hesaplama Türü, Net Fiyat`,
        url: `marketplaces/shipping-template/${activeProviderWithName.id}`,
        category: 'Kargo Şablonu',
      },
      {
        header: `${providerSettings.title} Pazaryeri > Local Pazaryeri Ürünleriniz  ${activeProviderWithName.storeName}`,
        searchTerms: `${providerSettings.title} pazaryeri, ${activeProviderWithName.storeName},${providerSettings.title} Local Pazaryeri Ürünleri ,${providerSettings.title} Local Ürünleri İndir ,Local Pazaryeri Ürünleri, Local Ürünleri İndir`,
        url: `/marketplaces/marketplace-product-local/${activeProviderWithName.id}`,
        category: 'Local Pazaryeri Ürünleriniz',
      },
      {
        header: `${providerSettings.title} Pazaryeri > Server Pazaryeri Ürünleriniz ${activeProviderWithName.storeName}`,
        searchTerms: `${providerSettings.title} pazaryeri, ${activeProviderWithName.storeName},${providerSettings.title} Server Pazaryeri Ürünleri ,${providerSettings.title} Server Ürünleri İndir ,Server Pazaryeri Ürünleri, Server Ürünleri İndir`,
        url: `/marketplaces/marketplace-product-server/${activeProviderWithName.id}`,
        category: 'Server Pazaryeri Ürünleriniz',
      }
    );
    this.fuse = new Fuse(this.searchItems, {
      keys: ['header', 'searchTerms'],
      threshold: 0.3,
      includeScore: true,
    });
  };

  getUniqueCategories(): string[] {
    return [
      ...new Set(this.searchedItems.map((item) => item.category || 'Diğer')),
    ];
  }

  getItemsByCategory(category: string): SearchItemsInterface[] {
    return this.searchedItems.filter(
      (item) => (item.category || 'Diğer') === category
    );
  }

  @HostListener('window:keydown', ['$event'])
  handleKeyDown(event: KeyboardEvent) {
    if (event.altKey && event.key.toLowerCase() === 'k') {
      this.visible5 = true;
    }
  }
}
