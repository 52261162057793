import {
  Component,
  computed,
  effect,
  inject,
  model,
  output,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { Button } from 'primeng/button';
import { Popover } from 'primeng/popover';
import { Slider } from 'primeng/slider';
import { ToggleSwitch } from 'primeng/toggleswitch';
import { DatabaseService } from '../../../core/database/database_service';
import { ThemeService } from '../../../core/services/theme/theme.service';
import { BroadcastChannelService } from '../../../core/broadcast-channel/services/broadcast-channel.service';
import { primaryColors } from './consts/primary-colors';
import { surfaceColors } from './consts/surface-colors';
import { FormsModule } from '@angular/forms';
import { Avatar } from 'primeng/avatar';
import { Dialog } from 'primeng/dialog';
import { OrderList } from 'primeng/orderlist';
import { MenuItem, PrimeTemplate } from 'primeng/api';

@Component({
  selector: 'magenty-change-theme',
  imports: [
    CommonModule,
    Button,
    Slider,
    ToggleSwitch,
    FormsModule,
    Avatar,
    Dialog,
    OrderList,
    PrimeTemplate,
  ],
  templateUrl: './change-theme.component.html',
  styleUrl: './change-theme.component.scss',
})
export class ChangeThemeComponent {
  dbService = inject(DatabaseService);
  themeService = inject(ThemeService);
  broadcastChannelService = inject(BroadcastChannelService);

  @ViewChild('themePopover') themePopover!: Popover;

  primaryColors = primaryColors;
  surfaceColors = surfaceColors;
  themes = ['Aura', 'Material', 'Lara', 'Nora'];
  menuVisible = model<boolean>(false);
  mainsSidebarItems = model<MenuItem[]>([]);
  otherSidebarItems = model<MenuItem[]>([]);
  favoriteSidebarItems = model<MenuItem[]>([]);
  filteredFavoriteItems = computed(() => {
    const filteredItems = this.favoriteSidebarItems().filter((favoriteItem) => {
      if (favoriteItem.url?.includes('marketplaces/dashboard')) {
        const providerId = favoriteItem.url.substring(
          favoriteItem.url.lastIndexOf('/') + 1
        );

        return this.activeUniqProviderList().some(
          (provider) => provider.providerId === providerId
        );
      }

      return true;
    });

    return filteredItems;
  });

  oldMainsSidebarItems: MenuItem[] = [];
  oldOtherSidebarItems: MenuItem[] = [];
  oldFavoriteSidebarItems: MenuItem[] = [];

  menuRefresh = output<boolean>();

  fontSizeTimer: any;

  activeUniqProviderList = model<any[]>([]);

  activeTab = 0;

  tabs = [
    { label: 'Tema Ayarları', icon: 'pi-palette' },
    { label: 'Menü Düzenleme', icon: 'pi-pencil' },
    { label: 'Menü Sıralaması', icon: 'pi-sort-amount-down' },
  ];

  firstEntry = false;

  constructor() {
    effect(() => {
      if (this.menuVisible()) {
        this.openThemePopover();

        this.updateMenuItemsFavoriteStatus();
      }
    });
  }

  async saveThemeConfig() {
    await this.dbService.saveDB('themeConfig', this.themeService.themeConfig);
    this.broadcastChannelService.themeChangeChannelSend(
      this.themeService.themeConfig
    );
  }

  updateFontSize = () => {
    clearTimeout(this.fontSizeTimer);
    this.fontSizeTimer = setTimeout(() => {
      this.themeService.updateFontSize();
    }, 1000);
    this.saveThemeConfig();
  };

  updateDarkMode = (event: any) => {
    /*  this.themeService.themeConfig.darkMode = event.checked;*/
    this.themeService.updateDarkMode();
    this.saveThemeConfig();
  };

  updatePreset = (theme: string) => {
    this.themeService.themeConfig.baseTheme = theme;
    this.themeService.updatePreset();
    this.saveThemeConfig();
  };

  updatePrimaryColor = (color: string) => {
    this.themeService.themeConfig.primaryColor = color;
    this.themeService.updatePrimary();
    this.saveThemeConfig();
  };
  updateSurface = (surfaceColor: string) => {
    if (this.themeService.darkMode()) {
      this.themeService.themeConfig.surfaceDarkColor = surfaceColor;
    } else {
      this.themeService.themeConfig.surfaceLightColor = surfaceColor;
    }

    this.themeService.updateSurface();
    this.saveThemeConfig();
  };

  public openThemePopover() {
    if (!this.firstEntry) {
      this.oldMainsSidebarItems = [...this.mainsSidebarItems()];
      this.oldOtherSidebarItems = [...this.otherSidebarItems()];
      this.oldFavoriteSidebarItems = [...this.favoriteSidebarItems()];
      this.firstEntry = true;
    }
  }

  updateMenuItemsFavoriteStatus() {
    const updateItems = (items: MenuItem[]) => {
      items.forEach((item) => {
        if (item) {
          item['favorite'] = this.favoriteSidebarItems().some(
            (fav) => fav.label === item.label
          );

          if (item.items) {
            item.items.forEach((subItem) => {
              if (subItem) {
                subItem['favorite'] = this.favoriteSidebarItems().some(
                  (fav) =>
                    fav.label === subItem.label &&
                    (!fav.label || fav.label === item.label)
                );
              }
            });
            updateItems(item.items);
          }
        }
      });
    };

    updateItems(this.mainsSidebarItems());
  }
  toggleFavorite(item: MenuItem) {
    setTimeout(() => {
      this.menuRefresh.emit(false);
      if (item['favorite']) {
        const exists = this.favoriteSidebarItems().some(
          (f) => f.label === item.label
        );

        // Eğer item yoksa ekle
        if (!exists) {
          this.favoriteSidebarItems().push(item);
        }
      } else {
        const foundIndex = this.favoriteSidebarItems().findIndex(
          (f) => f.label === item.label
        );
        if (foundIndex > -1) {
          this.favoriteSidebarItems().splice(foundIndex, 1);
        }
      }

      const temp = [...this.favoriteSidebarItems()];
      this.favoriteSidebarItems.set([]);
      this.favoriteSidebarItems.set(temp);

      setTimeout(() => {
        this.menuRefresh.emit(true);
      }, 50);
    }, 100);
  }

  reloadMenu = () => {
    this.menuRefresh.emit(false);
    setTimeout(() => {
      this.menuRefresh.emit(true);
    }, 100);
  };

  decreaseFontSize = () => {
    if (this.themeService.themeConfig.fontSize > 8) {
      this.themeService.themeConfig.fontSize -= 1;
      this.updateFontSize();
    }
  };
  increaseFontSize = () => {
    if (this.themeService.themeConfig.fontSize < 24) {
      this.themeService.themeConfig.fontSize += 1;
      this.updateFontSize();
    }
  };

  closeMenu = () => {
    this.mainsSidebarItems.set(this.oldMainsSidebarItems);
    this.otherSidebarItems.set(this.oldOtherSidebarItems);
    this.favoriteSidebarItems.set(this.oldFavoriteSidebarItems);
    this.menuVisible.set(false);
  };
  saveMenuSettings() {
    const sidebarConfig = {
      mainsSidebarItems: this.mainsSidebarItems(),
      otherSidebarItems: this.otherSidebarItems(),
      favoriteSidebarItems: this.favoriteSidebarItems(),
    };

    this.dbService.saveDB('sidebarConfig', sidebarConfig).then((f) => {
      this.menuVisible.set(false);
    });
  }
}
