import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  effect,
  inject,
  model,
  OnDestroy,
  OnInit,
  output,
  ViewChild,
} from '@angular/core';
import { Button } from 'primeng/button';
import { Drawer } from 'primeng/drawer';
import { NgClass } from '@angular/common';
import { RouterLink } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { MonitorsService } from '../../../core/services/monitors/monitors.service';
import {
  MonitorButtonTypeEnum,
  MonitorItemsPipe,
  MonitorOperationsPipe,
  MonitorStatusColorPipe,
  monitorStatusConst,
  MonitorStatusEnum,
  MonitorStatusPipe,
  ProviderGroupEnum,
  ProviderIconPipe,
  providerSettingsFunction,
  QuestTypeEnum,
  QuestTypePipe,
  Searchv2Class,
  SearchV2LogicType,
  SearchV2MatchedOptionEnumInterface,
} from '@component-library';
import { Monitor } from '../../../core/interfaces/monitor/monitor-interface';
import { TuiAvatarModule } from '@taiga-ui/kit';
import { Chip } from 'primeng/chip';
import { Tooltip } from 'primeng/tooltip';
import { Dialog } from 'primeng/dialog';
import { FormsModule } from '@angular/forms';
import { InputGroup } from 'primeng/inputgroup';
import { InputGroupAddon } from 'primeng/inputgroupaddon';
import { ProviderProductInterface } from '../../../core/interfaces/product/product';
import { ProviderProductDetailMainComponent } from '../../provider-products/detail/main/provider-product-detail-main.component';
import { ProviderProductsService } from '../../../core/services/provider-products/provider-products.service';
import { GlobalMessageService } from '../../../core/services/global-services/global-message-service/global-message.service';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { GlobalConfirmationService } from '../../../core/services/global-services/global-confirmation-service/global-confirmation.service';
import { ProductService } from '../../../core/services/products/product.service';
import { BroadcastChannelService } from '../../../core/broadcast-channel/services/broadcast-channel.service';
import { ToggleSwitch } from 'primeng/toggleswitch';
import { Table, TableModule } from 'primeng/table';
import { InputText } from 'primeng/inputtext';
import { Select } from 'primeng/select';
import { DatabaseService } from '../../../core/database/database_service';

@Component({
  selector: 'magenty-tasks-sidebar',
  imports: [
    Button,
    Drawer,
    RouterLink,
    QuestTypePipe,
    MonitorStatusPipe,
    ProviderIconPipe,
    TuiAvatarModule,
    Chip,
    NgClass,
    MonitorStatusColorPipe,
    Tooltip,
    MonitorItemsPipe,
    Dialog,
    FormsModule,
    ToggleSwitch,
    TableModule,
    InputText,
    InputGroup,
    InputGroupAddon,
    Select,
  ],
  providers: [
    DialogService,
    MonitorItemsPipe,
    MonitorStatusPipe,
    QuestTypePipe,
  ],
  templateUrl: './tasks-sidebar.component.html',
  styleUrl: './tasks-sidebar.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TasksSidebarComponent implements OnDestroy, OnInit {
  monitorService = inject(MonitorsService);
  productService = inject(ProductService);
  providerProductsService = inject(ProviderProductsService);
  globalMessageService = inject(GlobalMessageService);
  globalConfirmationService = inject(GlobalConfirmationService);
  broadcastChannelService = inject(BroadcastChannelService);
  dialogService = inject(DialogService);
  monitorItemsPipe = inject(MonitorItemsPipe);
  questTypePipe = inject(QuestTypePipe);
  databaseService = inject(DatabaseService);

  providerProductEditRef: DynamicDialogRef | undefined;

  sidebarVisible = model(false);
  inProcessTasksCount = output<number>();
  tasks: Monitor[] = [];

  private destroy$ = new Subject<void>();
  taskDetailDialog = false;
  selectedTask: Monitor | undefined;

  timeout = 30;
  timerActive = false;
  private timerInterval?: any;
  openNotification = true;

  @ViewChild('dt') dt: Table | undefined;
  filtered = false;
  previousTaskStates = new Map<string, MonitorStatusEnum>();

  constructor(private cdr: ChangeDetectorRef) {
    this.broadcastChannelService.tasksSidebarChannel.onmessage = () => {
      this.getTasks();
      this.startTimer();
    };
    this.databaseService.getDB('openNotification').then((f) => {
      if (f !== undefined) {
        this.openNotification = f;
      } else {
        this.openNotification = true;
      }
    });

    effect(() => {
      if (this.monitorService.tasksRefresh() > 0) {
        this.getTasks();
        this.startTimer();
      }
    });
  }

  ngOnInit() {
    this.getTasks();
  }

  ngOnDestroy() {
    this.stopTimer();
  }

  saveDb = () => {
    this.databaseService.saveDB('openNotification', this.openNotification);
  };

  onFilter = (event: any) => {
    const filters = event.filters;
    this.filtered = false;
    Object.keys(filters).forEach((key) => {
      if (key === 'global' && filters[key].value !== null) {
        this.filtered = true;
      } else if (
        Array.isArray(filters[key].at(0).value) &&
        filters[key].at(0).value.length !== 0
      ) {
        this.filtered = true;
      } else if (
        !Array.isArray(filters[key].at(0).value) &&
        filters[key].at(0).value !== null
      ) {
        this.filtered = true;
      }
    });
  };

  stopTimer() {
    this.timerActive = false;
    this.timeout = 30;
    if (this.timerInterval) {
      clearInterval(this.timerInterval);
    }
  }

  startTimer() {
    if (this.timerInterval) {
      clearInterval(this.timerInterval);
    }
    this.timerActive = true;
    this.timeout = 30;
    this.timerInterval = setInterval(() => {
      if (this.timeout > 0) {
        this.timeout--;
        this.cdr.detectChanges();
      } else {
        this.timeout = 30;
        this.getTasks();
      }
    }, 1000);
  }

  openDetails(task: Monitor) {
    this.selectedTask = task;
    this.taskDetailDialog = true;
  }

  showTaskMessages = (tasks: Monitor[]) => {
    tasks.forEach((task) => {
      const previousStatus = this.previousTaskStates.get(task.Id);
      const previousStatusCondition =
        previousStatus !== undefined &&
        previousStatus === MonitorStatusEnum.InProcess;

      const taskCondition = task.Status !== MonitorStatusEnum.InProcess;

      if (previousStatusCondition && taskCondition) {
        const taskItems = task.Items ?? [];
        const completedCount = this.monitorItemsPipe.transform(taskItems, 1);
        const notCompletedCount = this.monitorItemsPipe.transform(taskItems, 2);
        const inProcess = this.monitorItemsPipe.transform(taskItems, 3);
        const questType = this.questTypePipe.transform(task.QuestType) ?? '';
        const statusMessage = `${completedCount} başarılı, ${notCompletedCount} başarısız, ${inProcess} işlemde`;

        let message = '';
        if (task.Identity !== 0) {
          const provider = providerSettingsFunction(task.Identity);
          const groupTypes: { [key: number]: string } = {
            [ProviderGroupEnum.Marketplace]: 'Pazaryeri',
            [ProviderGroupEnum.ECommerce]: 'E-Ticaret',
            [ProviderGroupEnum.EExport]: 'E-İhracat',
            [ProviderGroupEnum.EInvoice]: 'E-Fatura',
            [ProviderGroupEnum.Cargo]: 'Kargo',
            [ProviderGroupEnum.None]: '',
          };

          message = `${provider.title} adlı ${
            groupTypes[provider.providerGroup]
          } için `;
        }

        const statusText =
          task.Status === MonitorStatusEnum.Completed
            ? 'tamamlandı.'
            : 'iptal edildi.';

        this.globalMessageService.showStatusMessages({
          status: true,
          summary: questType.toString(),
          infoDetail: `${message}${questType} görevi ${statusText}
              ${task?.Items?.length > 0 ? statusMessage : ''}`,
          errorDetail: '',
        });
      }

      this.previousTaskStates.set(task.Id, task.Status);
    });
    const temp = tasks.every((f) => f.Status !== MonitorStatusEnum.InProcess);

    if (temp) {
      this.stopTimer();
    }
  };

  getTasks = () => {
    const request = new Searchv2Class();

    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    yesterday.setUTCHours(0, 0, 0, 0);
    today.setUTCHours(23, 59, 59, 999);

    request.Filters = [
      {
        Key: 'CreatedOn',
        Value: yesterday.toUTCString(),
        Value1: today.toUTCString(),
        MatchedOption:
          SearchV2MatchedOptionEnumInterface[
            SearchV2MatchedOptionEnumInterface.between
          ],
        LogicType: SearchV2LogicType[SearchV2LogicType.and],
        Id: 'CreatedOn',
      },
    ];

    request.DisplayColumns = [
      'Id',
      'QuestKey',
      'Status',
      'QuestType',
      'ExpiredDate',
      'CreatedOn',
      'ModifiedOn',
      'IdentityType',
      'Identity',
      'ProviderId',
      'Items.ItemGuid',
      'Items.Id',
      'Items.Identity',
      'Items.IdentityType',
      'Items.ProviderSku',
      'Items.MonitorStatus',
    ];

    request.PageSize = 50;
    this.timeout = 30;
    this.monitorService
      .searchv2(request)
      .pipe(takeUntil(this.destroy$))
      .subscribe((f) => {
        if (f && f.Status) {
          this.tasks = [...f.Items];

          const inProcessTasks =
            this.tasks.filter(
              (x) => x.Status === MonitorStatusEnum.InProcess
            ) ?? [];

          this.inProcessTasksCount.emit(inProcessTasks?.length || 0);
          if (inProcessTasks.length > 0) {
            this.startTimer();
          }

          if (this.openNotification) {
            this.showTaskMessages(this.tasks);
          }

          if (this.selectedTask) {
            this.selectedTask = this.tasks.find(
              (x) => x.Id === this.selectedTask?.Id
            );
          }
        }
      });
  };

  getProviderProduct = (id: string | null) => {
    this.providerProductsService
      .get(id ?? '')
      .pipe(takeUntil(this.destroy$))
      .subscribe((f) => {
        if (!f || !f.Status || f.ProviderProduct.MarketplaceIdentity === 0) {
          this.globalMessageService.showMessage(
            'errorMessage',
            'Ürün Detayı',
            'Bu ürün koduna sahip bir ürün bulunamadı.'
          );
          return;
        }

        this.openProviderProductDetail(f.ProviderProduct);
      });
  };

  openProviderProductDetail = (providerProduct: ProviderProductInterface) => {
    const providerSettings = providerSettingsFunction(
      providerProduct.MarketplaceIdentity
    );
    this.providerProductEditRef = this.dialogService.open(
      ProviderProductDetailMainComponent,
      {
        header: `${providerSettings?.title} Ürün Düzenleme`,
        width: '80vw',
        modal: true,
        closable: true,
        data: {
          providerProduct: providerProduct,
          providerId: providerProduct?.ProviderId,
        },
        breakpoints: {
          '960px': '75vw',
          '640px': '90vw',
        },
      }
    );

    this.providerProductEditRef.onClose
      .pipe(takeUntil(this.destroy$))
      .subscribe((f) => {});
  };
  protected readonly providerSettingsFunction = providerSettingsFunction;
  protected readonly MonitorStatusPipe = MonitorStatusPipe;
  protected readonly MonitorOperationsPipe = MonitorOperationsPipe;
  protected readonly MonitorButtonTypeEnum = MonitorButtonTypeEnum;
  protected readonly monitorStatusConst = monitorStatusConst;
  protected readonly QuestTypeEnum = QuestTypeEnum;
}
