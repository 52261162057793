import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { StoreService } from '../store/store.service';

export const purchaseGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);
  const storeService = inject(StoreService);
  if (storeService?.remainingTime() > 0) {
    return true;
  }
  router.navigate(['/purchase']);
  return false;
};
