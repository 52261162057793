<p-blockUI [blocked]="storeService.blockedUi()">

    <div class="flex flex-col items-center justify-center gap-4 w-full bg-black/90">
        <img src="assets/gif/refresh-white.gif" width="150" height="150" alt="template-download" />

        <div class="text-center mt-4">
            <p class="text-lg text-white">Veriler Yükleniyor</p>
            <!--    <p class="text-md text-white">{{ percent }}%</p>-->

        </div>
    </div>


</p-blockUI>



<div
        class="min-h-screen relative lg:static bg-[var(--p-surface-50)] dark:bg-[var(--p-surface-900)]">
    <!-- Sidebar -->
    <aside id="app-sidebar-1"
           [class.w-[19rem]]="!isSidebarCollapsed"
           [class.w-[4rem]]="isSidebarCollapsed"
           class="fixed inset-y-0 left-0 z-40 transform transition-all duration-300 ease-in-out lg:translate-x-0"
           [ngClass]="{'translate-x-0': isSidebarOpen, '-translate-x-full': !isSidebarOpen}">

        <!-- Sidebar Content -->
        <div class="flex h-full flex-col bg-white dark:bg-[var(--p-surface-900)] border-r border-surface">
            <!-- Logo ve Collapse Button -->
            <div class="relative flex h-16 shrink-0 items-center border-b border-surface">
                <!-- Logo Container -->
                <div class="flex-1 flex items-center px-4">
                    <a routerLink="dashboard" class="flex items-center">
                        <img *ngIf="!isSidebarCollapsed"
                             [priority]="true"
                             ngSrc="http://content.magenty.com/static/img/MagentyV2Logo.png"
                             alt="Magenty Logo"
                             height="90"
                             width="275" />
                        <!-- Küçük Logo (Collapsed durumda) -->
                        <img *ngIf="isSidebarCollapsed"
                             [priority]="true"
                             ngSrc="https://magenty.com/wp-content/uploads/magenty-favicon.ico"
                             alt="Magenty"
                             class="w-8 h-8"
                             height="32"
                             width="32" />
                    </a>
                </div>

                <!-- Modern Collapse Toggle Button -->
                <div
                        [class]="isSidebarCollapsed ? 'absolute -right-10 top-1/2 -translate-y-1/2 z-10' : 'absolute -right-4 top-1/2 -translate-y-1/2 z-10'">
                    <button
                            (click)="toggleSidebarCollapse()"
                            class="hidden lg:flex items-center justify-center w-8 h-8 rounded-full
          bg-surface-100 hover:bg-surface-200 dark:bg-surface-800  dark:hover:bg-surface-700
         border border-surface-200 dark:border-surface-700
         transition-all duration-200 group">
                        <i [class]="isSidebarCollapsed ? 'pi pi-chevron-right' : 'pi pi-chevron-left'"
                           class="text-primary dark:text-primary
            group-hover:text-surface-400 dark:group-hover:text-surface-400
            transition-colors text-lg"></i>
                    </button>



                </div>
            </div>

            <!-- Navigation -->
            <div class="flex-1 overflow-y-auto">
                <div class="py-4">
                    <!-- Normal Panel Menu -->
                    @if (!isSidebarCollapsed) {
                        @if (menuRefresh && favoriteSidebarItems &&  favoriteSidebarItems.length > 0) {

                            <div class="px-1 pb-4 bg-surface-0 dark:bg-surface-900 rounded-lg shadow-sm">
                                <!-- Başlık kısmı -->
                                <div class="flex items-center justify-between p-3 pt-0 border-b border-surface-200 dark:border-surface-700">
                                    <div class="flex items-center gap-2">
                                        <i class="pi pi-star-fill text-yellow-500 text-lg"></i>
                                        <span class="text-lg font-medium text-surface-800 dark:text-white bg-clip-text">
                    Sık Kullanılanlar
                  </span>
                                    </div>
                                    <span class="text-xs text-surface-600 dark:text-surface-400 bg-surface-100 dark:bg-surface-800 px-2 py-1 rounded-full">
                  {{ favoriteSidebarItems?.length || 0 }} öğe
                </span>
                                </div>

                                <div class=" p-3 border-b border-surface-200 dark:border-surface-700">
                                    <p-panelMenu [model]="favoriteSidebarItems" styleClass="w-full custom-panel-menu">
                                        <ng-template #item let-item>
                                            @if (!item.seperator) {
                                                <a [routerLinkActive]="'bg-surface-100 dark:bg-surface-700'" [routerLink]="item.url"
                                                   class="flex items-center py-2 px-2 cursor-pointer group hover:bg-surface-50 dark:hover:bg-surface-700">
                                                    @if (item.icon) {
                                                        @if (item.icon.includes('http')) {

                                                            <p-avatar
                                                                    [image]="item.icon" styleClass="mr-2" class="dark:bg-surface-300" size="normal"
                                                                    shape="circle"></p-avatar>
                                                        } @else {
                                                            <i [class]="item.icon + ' text-primary group-hover:text-inherit'"></i>
                                                        }

                                                    }

                                                    <span class="ml-2">
                     {{ item.label }}
                 </span>

                                                    @if (item.badge)

                                                    {
                                                        @if (item.badgeStyleClass === 'saleCount') {

                                                            @switch (+item.badge) {
                                                                @case (OrderStatusEnum.New) {
                                                                    <span
                                                                            class="inline-flex items-center justify-center ml-auto text-slate-800 rounded-full"
                                                                            [ngClass]="+item.badge | orderStatusColor"
                                                                            style="min-width: 1.5rem; min-height: 1.5rem"><span
                                                                            class="px-1">{{ orderCounts.newOrder }}</span></span>
                                                                }
                                                                @case (OrderStatusEnum.Ready) {
                                                                    <span
                                                                            class="inline-flex items-center justify-center ml-auto text-slate-800 rounded-full"
                                                                            [ngClass]="+item.badge | orderStatusColor"
                                                                            style="min-width: 1.5rem; min-height: 1.5rem"><span
                                                                            class="px-1">{{ orderCounts.readyToOrder }}</span></span>
                                                                }
                                                                @case (OrderStatusEnum.Shipped) {
                                                                    <span
                                                                            class="inline-flex items-center justify-center ml-auto text-slate-800 rounded-full"
                                                                            [ngClass]="+item.badge | orderStatusColor"
                                                                            style="min-width: 1.5rem; min-height: 1.5rem"><span
                                                                            class="px-1">{{ orderCounts.shippedOrder }}</span></span>
                                                                }
                                                                @case (OrderStatusEnum.Canceled) {
                                                                    <span
                                                                            class="inline-flex items-center justify-center ml-auto text-slate-800 rounded-full"
                                                                            [ngClass]="+item.badge | orderStatusColor"
                                                                            style="min-width: 1.5rem; min-height: 1.5rem"><span
                                                                            class="px-1">{{ orderCounts.cancellationRefundOrder }}</span></span>
                                                                }
                                                                @case (OrderStatusEnum.Invoiced) {
                                                                    <span
                                                                            class="inline-flex items-center justify-center ml-auto text-slate-800 rounded-full"
                                                                            [ngClass]="+item.badge | orderStatusColor"
                                                                            style="min-width: 1.5rem; min-height: 1.5rem"><span
                                                                            class="px-1">{{ orderCounts.invoicedOrder }}</span></span>
                                                                }
                                                            }


                                                        } @else if (item.badgeStyleClass === 'tasksCount' ) {
                                                            @if(inProcessTasksCount > 0)
                                                            { <span
                                                                    class="inline-flex items-center justify-center ml-auto text-slate-800 rounded-full"
                                                                    [ngClass]="MonitorStatusEnum.InProcess | monitorStatusColor"
                                                                    style="min-width: 1.5rem; min-height: 1.5rem"><span
                                                                    class=" px-1">{{ inProcessTasksCount }}</span></span>
                                                            }
                                                        } @else {
                                                            <span
                                                                    class="inline-flex items-center justify-center ml-auto text-slate-800 rounded-full"
                                                                    [ngClass]="item.badgeStyleClass"
                                                                    style="min-width: 1.5rem; min-height: 1.5rem"><span
                                                                    class="px-1">{{ item.badge }}</span></span>
                                                        }
                                                    }


                                                    <i *ngIf="item.items" class="pi pi-angle-down text-primary ml-auto"></i>
                                                </a>
                                            }
                                        </ng-template>
                                    </p-panelMenu>
                                </div>
                            </div>

                        }
                        @if (   menuRefresh) {
                            <p-panelMenu [model]="mainsSidebarItems" [multiple]="true" styleClass="w-full custom-panel-menu">
                                <ng-template #item let-item>
                                    @if (!item.seperator) {
                                        <a [routerLinkActive]="'bg-surface-100 dark:bg-surface-700'" [routerLink]="item.url"
                                           class="flex items-center py-2 px-2 cursor-pointer group hover:bg-surface-50 dark:hover:bg-surface-700">
                                            @if (item.icon) {
                                                @if (item.icon.includes('http')) {

                                                    <p-avatar
                                                            [image]="item.icon" styleClass="mr-2" class="dark:bg-surface-300" size="normal"
                                                            shape="circle"></p-avatar>
                                                } @else {
                                                    <i [class]="item.icon + ' text-primary group-hover:text-inherit'"></i>
                                                }

                                            }

                                            <span class="ml-2">
                     {{ item.label }}
                 </span>

                                            @if (item.badge)

                                            {
                                                @if (item.badgeStyleClass === 'saleCount') {

                                                    @switch (+item.badge) {
                                                        @case (OrderStatusEnum.New) {
                                                            <span
                                                                    class="inline-flex items-center justify-center ml-auto text-slate-800 rounded-full"
                                                                    [ngClass]="+item.badge | orderStatusColor"
                                                                    style="min-width: 1.5rem; min-height: 1.5rem"><span
                                                                    class="px-1">{{ orderCounts.newOrder }}</span></span>
                                                        }
                                                        @case (OrderStatusEnum.Ready) {
                                                            <span
                                                                    class="inline-flex items-center justify-center ml-auto text-slate-800 rounded-full"
                                                                    [ngClass]="+item.badge | orderStatusColor"
                                                                    style="min-width: 1.5rem; min-height: 1.5rem"><span
                                                                    class="px-1">{{ orderCounts.readyToOrder }}</span></span>
                                                        }
                                                        @case (OrderStatusEnum.Shipped) {
                                                            <span
                                                                    class="inline-flex items-center justify-center ml-auto text-slate-800 rounded-full"
                                                                    [ngClass]="+item.badge | orderStatusColor"
                                                                    style="min-width: 1.5rem; min-height: 1.5rem"><span
                                                                    class="px-1">{{ orderCounts.shippedOrder }}</span></span>
                                                        }
                                                        @case (OrderStatusEnum.Canceled) {
                                                            <span
                                                                    class="inline-flex items-center justify-center ml-auto text-slate-800 rounded-full"
                                                                    [ngClass]="+item.badge | orderStatusColor"
                                                                    style="min-width: 1.5rem; min-height: 1.5rem"><span
                                                                    class="px-1">{{ orderCounts.cancellationRefundOrder }}</span></span>
                                                        }
                                                        @case (OrderStatusEnum.Invoiced) {
                                                            <span
                                                                    class="inline-flex items-center justify-center ml-auto text-slate-800 rounded-full"
                                                                    [ngClass]="+item.badge | orderStatusColor"
                                                                    style="min-width: 1.5rem; min-height: 1.5rem"><span
                                                                    class="px-1">{{ orderCounts.invoicedOrder }}</span></span>
                                                        }
                                                    }


                                                } @else if (item.badgeStyleClass === 'tasksCount' ) {
                                                    @if(inProcessTasksCount > 0)
                                                    { <span
                                                            class="inline-flex items-center justify-center ml-auto text-slate-800 rounded-full"
                                                            [ngClass]="MonitorStatusEnum.InProcess | monitorStatusColor"
                                                            style="min-width: 1.5rem; min-height: 1.5rem"><span
                                                            class=" px-1">{{ inProcessTasksCount }}</span></span>
                                                    }
                                                } @else {
                                                    <span
                                                            class="inline-flex items-center justify-center ml-auto text-slate-800 rounded-full"
                                                            [ngClass]="item.badgeStyleClass"
                                                            style="min-width: 1.5rem; min-height: 1.5rem"><span
                                                            class="px-1">{{ item.badge }}</span></span>
                                                }
                                            }


                                            <i *ngIf="item.items" class="pi pi-angle-down text-primary ml-auto"></i>
                                        </a>
                                    }
                                </ng-template>
                            </p-panelMenu>
                        }


                        <p-panelMenu [model]="otherSidebarItems" styleClass="w-full mt-4 custom-panel-menu">
                            <ng-template #item let-item>
                                <a
                                        [routerLinkActive]="'bg-surface-100 dark:bg-surface-700'"
                                        [routerLink]="item.url"
                                        class="flex items-center cursor-pointer py-2 px-2 hover:bg-surface-50 dark:hover:bg-surface-700 transition-colors">

                                    @if (item.icon) {
                                        <i [class]="item.icon + ' text-primary group-hover:text-inherit'"></i>
                                    }

                                    <span class="ml-2">{{ item.label }}</span>

                                    @if (item.badge) {
                                        <span
                                                class="ml-auto inline-flex items-center justify-center rounded-full px-2 py-1"
                                                [ngClass]="item.badgeStyleClass">
                    {{ item.badge }}
                  </span>
                                    }
                                    <i *ngIf="item.items" class="pi pi-angle-down text-primary ml-auto"></i>
                                </a>
                            </ng-template>
                        </p-panelMenu>
                    }

                    <!-- Collapsed Tiered Menu -->
                    @if (isSidebarCollapsed) {
                        <div class="flex flex-col gap-2">
                            @if (favoriteSidebarItems.length>0) {
                                <div class="relative">
                                    <button
                                        #btn
                                        type="button"
                                        class="w-full p-2 flex justify-center hover:bg-surface-100 dark:hover:bg-surface-700 rounded-lg"
                                        (click)="menu.toggle($event)">
                                        <div pTooltip="Sık kullanılanlar" tooltipPosition="right">
                                            <i class="pi pi-star-fill text-yellow-500 !text-lg"></i>
                                        </div>
                                    </button>
                                    <p-tieredMenu
                                        #menu
                                        [model]="favoriteSidebarItems"
                                        [popup]="true"
                                        [appendTo]="'body'"
                                        [baseZIndex]="1000"

                                        [styleClass]="'w-56'">
                                        <ng-template #item let-item>
                                            @if (!item.seperator) {
                                                <a [routerLinkActive]="'bg-surface-200 dark:bg-surface-700'" [routerLink]="item.url"
                                                   class="flex items-center px-4 py-3 cursor-pointer group">
                                                    @if (item.icon) {
                                                        @if (item.icon.includes('http')) {

                                                            <p-avatar
                                                                    [image]="item.icon" styleClass="mr-2" class="dark:bg-surface-300" size="normal"
                                                                    shape="circle"></p-avatar>
                                                        } @else {
                                                            <i [class]="item.icon + ' text-primary group-hover:text-inherit'"></i>
                                                        }

                                                    }

                                                    <span class="ml-2">
                             {{ item.label }}
                         </span>

                                                    @if (item.badge)

                                                    {
                                                        @if (item.badgeStyleClass === 'saleCount') {

                                                            @switch (+item.badge) {
                                                                @case (OrderStatusEnum.New) {
                                                                    <span
                                                                            class="inline-flex items-center justify-center ml-auto text-slate-800 rounded-full"
                                                                            [ngClass]="+item.badge | orderStatusColor"
                                                                            style="min-width: 1.5rem; min-height: 1.5rem"><span
                                                                            class="px-1">{{ orderCounts.newOrder }}</span></span>
                                                                }
                                                                @case (OrderStatusEnum.Ready) {
                                                                    <span
                                                                            class="inline-flex items-center justify-center ml-auto text-slate-800 rounded-full"
                                                                            [ngClass]="+item.badge | orderStatusColor"
                                                                            style="min-width: 1.5rem; min-height: 1.5rem"><span
                                                                            class="px-1">{{ orderCounts.readyToOrder }}</span></span>
                                                                }
                                                                @case (OrderStatusEnum.Shipped) {
                                                                    <span
                                                                            class="inline-flex items-center justify-center ml-auto text-slate-800 rounded-full"
                                                                            [ngClass]="+item.badge | orderStatusColor"
                                                                            style="min-width: 1.5rem; min-height: 1.5rem"><span
                                                                            class="px-1">{{ orderCounts.shippedOrder }}</span></span>
                                                                }
                                                                @case (OrderStatusEnum.Canceled) {
                                                                    <span
                                                                            class="inline-flex items-center justify-center ml-auto text-slate-800 rounded-full"
                                                                            [ngClass]="+item.badge | orderStatusColor"
                                                                            style="min-width: 1.5rem; min-height: 1.5rem"><span
                                                                            class="px-1">{{ orderCounts.cancellationRefundOrder }}</span></span>
                                                                }
                                                                @case (OrderStatusEnum.Invoiced) {
                                                                    <span
                                                                            class="inline-flex items-center justify-center ml-auto text-slate-800 rounded-full"
                                                                            [ngClass]="+item.badge | orderStatusColor"
                                                                            style="min-width: 1.5rem; min-height: 1.5rem"><span
                                                                            class="px-1">{{ orderCounts.invoicedOrder }}</span></span>
                                                                }
                                                            }


                                                        }@else if (item.badgeStyleClass === 'tasksCount' ) {
                                                            @if(inProcessTasksCount > 0)
                                                            { <span
                                                                    class="inline-flex items-center justify-center ml-auto text-slate-800 rounded-full"
                                                                    [ngClass]="MonitorStatusEnum.InProcess | monitorStatusColor"
                                                                    style="min-width: 1.5rem; min-height: 1.5rem"><span
                                                                    class=" px-1">{{ inProcessTasksCount }}</span></span>
                                                            }
                                                        }
                                                        @else {
                                                            <span
                                                                    class="inline-flex items-center justify-center ml-auto text-slate-800 rounded-full"
                                                                    [ngClass]="item.badgeStyleClass"
                                                                    style="min-width: 1.5rem; min-height: 1.5rem"><span
                                                                    class="px-1">{{ item.badge }}</span></span>
                                                        }
                                                    }


                                                    <i *ngIf="item.items" class="pi pi-angle-down text-primary ml-auto"></i>
                                                </a>
                                            }
                                        </ng-template>
                                    </p-tieredMenu>

                                </div>
                            }

                            <!-- Ana Menü -->
                            @for (menuItem of mainsSidebarItems; track $index) {
                                <div class="relative">
                                    @if (menuItem.items) {
                                        <button
                                            #btn
                                            type="button"
                                            class="w-full p-2 flex justify-center hover:bg-surface-100 dark:hover:bg-surface-700 rounded-lg"
                                            (click)="menu.toggle($event)">
                                            <i [class]="menuItem.icon + ' text-primary'" [pTooltip]="menuItem.label" tooltipPosition="right"
                                               style="font-size: 1.5rem"></i>
                                        </button>
                                        <p-tieredMenu
                                            #menu
                                            [model]="menuItem.items"
                                            [popup]="true"
                                            [appendTo]="'body'"
                                            [baseZIndex]="1000"

                                            [styleClass]="'w-56'">
                                            <ng-template #item let-item>
                                                @if (!item.seperator) {
                                                    <a [routerLinkActive]="'bg-surface-200 dark:bg-surface-700'" [routerLink]="item.url"
                                                       class="flex items-center px-4 py-3 cursor-pointer group">
                                                        @if (item.icon) {
                                                            @if (item.icon.includes('http')) {

                                                                <p-avatar
                                                                        [image]="item.icon" styleClass="mr-2" class="dark:bg-surface-300" size="normal"
                                                                        shape="circle"></p-avatar>
                                                            } @else {
                                                                <i [class]="item.icon + ' text-primary group-hover:text-inherit'"></i>
                                                            }

                                                        }

                                                        <span class="ml-2">
                             {{ item.label }}
                         </span>

                                                        @if (item.badge)

                                                        {
                                                            @if (item.badgeStyleClass === 'saleCount') {

                                                                @switch (+item.badge) {
                                                                    @case (OrderStatusEnum.New) {
                                                                        <span
                                                                                class="inline-flex items-center justify-center ml-auto text-slate-800 rounded-full"
                                                                                [ngClass]="+item.badge | orderStatusColor"
                                                                                style="min-width: 1.5rem; min-height: 1.5rem"><span
                                                                                class="px-1">{{ orderCounts.newOrder }}</span></span>
                                                                    }
                                                                    @case (OrderStatusEnum.Ready) {
                                                                        <span
                                                                                class="inline-flex items-center justify-center ml-auto text-slate-800 rounded-full"
                                                                                [ngClass]="+item.badge | orderStatusColor"
                                                                                style="min-width: 1.5rem; min-height: 1.5rem"><span
                                                                                class="px-1">{{ orderCounts.readyToOrder }}</span></span>
                                                                    }
                                                                    @case (OrderStatusEnum.Shipped) {
                                                                        <span
                                                                                class="inline-flex items-center justify-center ml-auto text-slate-800 rounded-full"
                                                                                [ngClass]="+item.badge | orderStatusColor"
                                                                                style="min-width: 1.5rem; min-height: 1.5rem"><span
                                                                                class="px-1">{{ orderCounts.shippedOrder }}</span></span>
                                                                    }
                                                                    @case (OrderStatusEnum.Canceled) {
                                                                        <span
                                                                                class="inline-flex items-center justify-center ml-auto text-slate-800 rounded-full"
                                                                                [ngClass]="+item.badge | orderStatusColor"
                                                                                style="min-width: 1.5rem; min-height: 1.5rem"><span
                                                                                class="px-1">{{ orderCounts.cancellationRefundOrder }}</span></span>
                                                                    }
                                                                    @case (OrderStatusEnum.Invoiced) {
                                                                        <span
                                                                                class="inline-flex items-center justify-center ml-auto text-slate-800 rounded-full"
                                                                                [ngClass]="+item.badge | orderStatusColor"
                                                                                style="min-width: 1.5rem; min-height: 1.5rem"><span
                                                                                class="px-1">{{ orderCounts.invoicedOrder }}</span></span>
                                                                    }
                                                                }


                                                            }@else if (item.badgeStyleClass === 'tasksCount' ) {
                                                                @if(inProcessTasksCount > 0)
                                                                { <span
                                                                        class="inline-flex items-center justify-center ml-auto text-slate-800 rounded-full"
                                                                        [ngClass]="MonitorStatusEnum.InProcess | monitorStatusColor"
                                                                        style="min-width: 1.5rem; min-height: 1.5rem"><span
                                                                        class=" px-1">{{ inProcessTasksCount }}</span></span>
                                                                }
                                                            }
                                                            @else {
                                                                <span
                                                                        class="inline-flex items-center justify-center ml-auto text-slate-800 rounded-full"
                                                                        [ngClass]="item.badgeStyleClass"
                                                                        style="min-width: 1.5rem; min-height: 1.5rem"><span
                                                                        class="px-1">{{ item.badge }}</span></span>
                                                            }
                                                        }


                                                        <i *ngIf="item.items" class="pi pi-angle-down text-primary ml-auto"></i>
                                                    </a>
                                                }
                                            </ng-template>
                                        </p-tieredMenu>
                                    } @else {
                                        <a
                                                [routerLink]="menuItem.url"
                                                class="w-full p-2 flex justify-center hover:bg-surface-100 dark:hover:bg-surface-700 rounded-lg"
                                                [pTooltip]="menuItem.label"
                                                tooltipPosition="right">
                                            <i [class]="menuItem.icon + ' text-primary'" style="font-size: 1.5rem"></i>
                                        </a>
                                    }
                                </div>
                            }

                            <!-- Ayırıcı -->
                            <div class="border-t border-surface my-2"></div>

                            <!-- Diğer Menü -->
                            @for (menuItem of otherSidebarItems.at(0)?.items; track $index) {
                                <div class="relative">
                                    @if (menuItem.items) {
                                        <button
                                            #btn
                                            type="button"
                                            class="w-full p-2 flex justify-center hover:bg-surface-100 dark:hover:bg-surface-700 rounded-lg"
                                            (click)="menu.toggle($event)">
                                            <i [class]="menuItem.icon + ' text-primary'" [pTooltip]="menuItem.label" tooltipPosition="right"
                                               style="font-size: 1.5rem"></i>
                                        </button>
                                        <p-tieredMenu
                                            #menu
                                            [model]="menuItem.items"
                                            [popup]="true"
                                            [appendTo]="'body'"
                                            [baseZIndex]="1000"
                                            [styleClass]="'w-56'">
                                            <ng-template #item let-item>
                                                <a
                                                        [routerLinkActive]="'bg-surface-200 dark:bg-surface-700'"
                                                        [routerLink]="item.url"
                                                        class="flex items-center px-4 py-3 rounded-lg hover:bg-surface-100 dark:hover:bg-surface-700 transition-colors">

                                                    @if (item.icon) {
                                                        @if (item.icon.includes('http')) {
                                                            <p-avatar [image]="item.icon" styleClass="mr-2" class="dark:bg-surface-300" size="normal"
                                                                      shape="circle"></p-avatar>
                                                        } @else {
                                                            <i [class]="item.icon + ' text-primary group-hover:text-inherit'"></i>
                                                        }
                                                    }

                                                    <span class="ml-2">{{ item.label }}</span>

                                                    @if (item.badge) {
                                                        <span
                                                                class="ml-auto inline-flex items-center justify-center rounded-full px-2 py-1"
                                                                [ngClass]="item.badgeStyleClass">
                              {{ item.badge }}
                            </span>
                                                    }
                                                </a>
                                            </ng-template>
                                        </p-tieredMenu>
                                    } @else {
                                        <a
                                                [routerLink]="menuItem.url"
                                                class="w-full p-2 flex justify-center hover:bg-surface-100 dark:hover:bg-surface-700 rounded-lg"
                                                [pTooltip]="menuItem.label"
                                                tooltipPosition="right">
                                            <i [class]="menuItem.icon + ' text-primary'" style="font-size: 1.5rem"></i>
                                        </a>
                                    }
                                </div>
                            }
                        </div>
                    }
                </div>
            </div>

            <!-- Bottom Section -->
            <div class="mt-auto">
                <!-- Ücretsiz Eğitim Randevusu -->
              @if(storeService.store$()?.company?.base && storeService.store$()?.company?.base?.CompanyStatus===CompanyStatusEnum.Demo) {
                @if (!isSidebarCollapsed) {
                  <div class="px-4 mb-4" (click)="openAppointmentModal()">
                    <div
                      class="flex items-center justify-between p-3 rounded-lg bg-primary/10 dark:bg-primary/20 cursor-pointer hover:bg-primary/20 dark:hover:bg-primary/30 transition-all">
                      <div class="flex items-center gap-3">
                        <i class="pi pi-calendar text-primary !text-2xl"></i>
                        <div>
                          <p class="font-medium text-surface-900 dark:text-surface-0">Ücretsiz Eğitim</p>
                          <p class="text-sm text-surface-600 dark:text-surface-400">Randevu Oluşturun</p>
                        </div>
                      </div>
                      <i class="pi pi-arrow-right text-primary"></i>
                    </div>
                  </div>
                } @else {
                  <div class="px-1 mb-4" (click)="openAppointmentModal()">
                    <button
                      class="w-full p-2 flex justify-center hover:bg-surface-100 dark:hover:bg-surface-700 rounded-lg"

                      pTooltip="Ücretsiz Eğitim Randevusu"
                      tooltipPosition="right">
                      <i class="pi pi-calendar text-primary !text-2xl"></i>
                    </button>
                  </div>
                }
              }

                <!-- Canlı Destek -->
                @if (!isSidebarCollapsed) {
                    <div class="px-4 mb-4">
                        <div class="flex items-center justify-between p-3 rounded-lg bg-surface-100 dark:bg-surface-700">
                            <div class="flex items-center gap-3">
                                <i class="pi pi-comments text-primary !text-2xl"></i>
                                <div>
                                    <p class="font-medium text-surface-900 dark:text-surface-0">Destek</p>
                                    <p class="text-sm text-surface-600 dark:text-surface-400">Canlı Destek</p>
                                </div>
                            </div>
                            <div class="flex items-center">


                                <p-toggleswitch [(ngModel)]="isSupportOpen" (onChange)="toggleSupport()">
                                    <ng-template #handle let-checked="checked">
                                        <i [ngClass]="['!text-xs', 'pi', checked ? 'pi-check' : 'pi-times']"></i>
                                    </ng-template>
                                </p-toggleswitch>

                            </div>
                        </div>
                    </div>
                } @else {
                    <div class="px-1 mb-4">
                        <p-toggleswitch [(ngModel)]="isSupportOpen" (onChange)="toggleSupport()">
                            <ng-template #handle let-checked="checked">
                                <i [ngClass]="['!text-xs', 'pi', checked ? 'pi-check' : 'pi-times']"></i>
                            </ng-template>
                        </p-toggleswitch>

                    </div>
                }

                <!-- User Profile Section -->
                @if (!isSidebarCollapsed) {
                    <div class="border-t border-surface p-4">
                        <a routerLink="/profile"
                           class="flex items-center space-x-3 rounded-lg p-3 hover:bg-surface-100 dark:hover:bg-surface-700">

                            <img
                                    [src]="(panelSettings?.CompanyDetail?.ImageUrl | picture) === '../../../assets/images/no-img.png' ?
                     'assets/images/base/boy.png' :
                     (panelSettings?.CompanyDetail?.ImageUrl | picture)"
                                    class="h-10 w-10 rounded-full"
                                    alt="Profile" />

                            <div class="flex-1">
                                <p class="font-medium text-surface-900 dark:text-surface-0">
                                    {{ panelSettings?.CompanyDetail?.Name }} {{ panelSettings?.CompanyDetail?.Surname }}
                                </p>
                                <p class="text-sm text-surface-600 dark:text-surface-400">Yetkili</p>
                            </div>

                        </a>
                    </div>
                } @else {
                    <div class="border-t border-surface p-4">
                        <a routerLink="/profile"
                           class="flex items-center  rounded-lg  hover:bg-surface-100 dark:hover:bg-surface-700">
                            <img
                                    [src]="(panelSettings?.CompanyDetail?.ImageUrl | picture) === '../../../assets/images/no-img.png' ?
               'assets/images/base/boy.png' :
               (panelSettings?.CompanyDetail?.ImageUrl | picture)"
                                    class="h-8 w-8 rounded-full"
                                    alt="Profile" />
                        </a>
                    </div>
                }
            </div>
        </div>
    </aside>

    <!-- Main Content -->
    <div [class.lg:ml-[19rem]]="!isSidebarCollapsed" [class.lg:ml-[4rem]]="isSidebarCollapsed"
         class="transition-all duration-300">
        <!-- Top Bar -->
        <header
                class="sticky top-0 z-30 flex h-16 items-center justify-between border-b border-surface bg-white dark:bg-surface-900 px-4 lg:px-8">
            <!-- Left Section -->
            <div class="flex items-center justify-between lg:justify-start w-full lg:w-auto">
                <!-- Hamburger Menu for Mobile -->
                <button
                        class="lg:hidden rounded-lg p-2 hover:bg-surface-100 dark:hover:bg-surface-700"
                        (click)="isSidebarOpen = !isSidebarOpen">
                    <i class="pi pi-bars text-2xl"></i>
                </button>
                <!-- Search -->
                <magenty-main-search class="ml-3"></magenty-main-search>
                <!-- Logo for Mobile -->
                <div class="lg:hidden flex-1 flex justify-center">
                    <img
                            ngSrc="http://content.magenty.com/static/img/MagentyV2Logo.png"
                            alt="Magenty Logo"
                            height="45"
                            width="137"
                            priority="true" />
                </div>


            </div>

            <!-- Right Section -->
            <div class="flex items-center space-x-4">
                <!-- Version Number - Desktop Only -->
                <span class="hidden lg:inline-block text-sm text-surface-600 dark:text-surface-400">v{{ version }}</span>

                <!-- Theme Toggle - Desktop Only -->
                      <button
                        (click)="menuVisible=true"
                        type="button"
                        class="hidden lg:inline-flex items-center justify-center shrink-0 !border-transparent w-8 h-8 transition-colors duration-200 rounded-[6px] m-0 p-0 outline-transparent bg-[var(--card-background)] cursor-pointer relative group overflow-hidden ">
                                                    <span
                                                      class="absolute -top-5 -left-5 w-20 h-20 animate-spin rounded"
                                                      style="
                                                            animation-duration: 2s;
                                                            background: conic-gradient(
                                                                from 90deg,
                                                                #f97316, #f59e0b, #eab308, #84cc16,
                                                                #22c55e, #10b981, #14b8a6, #06b6d4,
                                                                #0ea5e9, #3b82f6, #6366f1, #8b5cf6,
                                                                #a855f7, #d946ef, #ec4899, #f43f5e
                                                            );">
                                                    </span>
                        <span
                          class="absolute z-2 bg-surface-0 dark:bg-surface-800 transition-all"
                          style="inset: 1.3px; border-radius: 4px;">
                                    </span>
                        <i class="pi pi-cog z-10"></i>
                      </button>

                <!-- Notifications - Desktop Only -->
                <button
                        class="hidden lg:block relative rounded-lg p-2 hover:bg-surface-100 dark:hover:bg-surface-700"
                        (click)="sidebarVisible = true">
                    <i class="pi pi-bell text-2xl" style="font-size: 1.5rem;"></i>
                    @if (notificationCount.critical + notificationCount.all > 0) {
                        <span class="absolute -top-1 -right-1 h-2 w-2 rounded-full bg-red-500 animate-pulse
"></span>
                    }
                </button>
                <button
                        id="tasks-sidebar-btn"
                        class="hidden lg:block relative rounded-lg p-2 hover:bg-surface-100 dark:hover:bg-surface-700"
                        (click)="tasksSidebarVisible = true;driverService.nextStep()">
                    <i class="pi pi-pen-to-square !text-2xl" style="font-size: 1.5rem;"></i>
                    @if (inProcessTasksCount > 0) {
                        <span
                                [ngClass]="MonitorStatusEnum.InProcess | monitorStatusColor"
                                class="absolute -top-1 -right-1 min-w-[20px] h-5 px-1.5 rounded-full  text-xs font-medium flex items-center justify-center text-surface-900">
                {{inProcessTasksCount}}
            </span>
                    }
                </button>

                <magenty-tasks-sidebar [(sidebarVisible)]="tasksSidebarVisible"
                                       (inProcessTasksCount)="inProcessTasksCount=$event" ></magenty-tasks-sidebar>


                <!-- Profile Menu -->
                <div class="relative">
                    <button
                            class="flex items-center space-x-3 rounded-lg p-2 hover:bg-surface-100 dark:hover:bg-surface-700"
                            (click)="menu5.toggle($event)">
                        <img
                                [src]="(panelSettings?.CompanyDetail?.ImageUrl | picture) === '../../../assets/images/no-img.png' ?
                     'assets/images/base/boy.png' :
                     (panelSettings?.CompanyDetail?.ImageUrl | picture)"
                                class="h-8 w-12 lg:w-8 rounded-full"
                                alt="Profile" />
                    </button>
                    <p-menu #menu5
                            [popup]="true"
                            [model]="items"
                            [styleClass]="'w-56'"
                            [appendTo]="'body'"
                            [baseZIndex]="1000"
                            [showTransitionOptions]="'0ms'"
                            [hideTransitionOptions]="'0ms'"
                            [autoZIndex]="true">
                        <ng-template pTemplate="item" let-item>

                            <!-- Mobil için ekstra menü öğeleri -->

                            @if (item.label === 'Bildirimler') {
                                <a class="flex items-center px-4 py-2 cursor-pointer hover:bg-surface-100 dark:hover:bg-surface-700"
                                   (click)="sidebarVisible = true">
                                    <i class="pi pi-bell mr-2 text-primary"></i>
                                    <span>Bildirimler</span>
                                    @if (notificationCount.critical + notificationCount.all > 0) {
                                        <span class="ml-auto bg-red-500 text-white text-xs rounded-full px-2 py-1 ">
                      {{ notificationCount.critical + notificationCount.all }}
                    </span>
                                    }
                                </a>
                            }
                            @else if (item.label === 'Görevler') {
                                <a  class="flex items-center px-4 py-2 cursor-pointer hover:bg-surface-100 dark:hover:bg-surface-700"
                                    (click)="tasksSidebarVisible = true;driverService.nextStep()">
                                    <i class="pi pi-pen-to-square mr-2 text-primary"></i>
                                    <span>Görevler</span>
                                    @if (inProcessTasksCount > 0) {
                                        <span
                                                [ngClass]="MonitorStatusEnum.InProcess | monitorStatusColor"
                                                class="ml-auto  text-white text-xs rounded-full px-2 py-1">
                      {{ inProcessTasksCount }}
                    </span>
                                    }
                                </a>
                            } @else if (item.label === 'Tema') {
                                <div  (click)="menuVisible=true" class="flex items-center px-4 py-2 hover:bg-surface-100 cursor-pointer dark:hover:bg-surface-700">
                                    <i class="pi pi-sun mr-2 text-primary"></i>
                                    <span>Tema</span>
                                    <div class="ml-auto">
                                      <button

                                        type="button"
                                        class="inline-flex items-center justify-center shrink-0 !border-transparent w-8 h-8 transition-colors duration-200 rounded-[6px] m-0 p-0 outline-transparent bg-[var(--card-background)] cursor-pointer relative group overflow-hidden ">
                                            <span
                                              class="absolute -top-5 -left-5 w-20 h-20 animate-spin rounded"
                                              style="
                                                    animation-duration: 2s;
                                                    background: conic-gradient(
                                                        from 90deg,
                                                        #f97316, #f59e0b, #eab308, #84cc16,
                                                        #22c55e, #10b981, #14b8a6, #06b6d4,
                                                        #0ea5e9, #3b82f6, #6366f1, #8b5cf6,
                                                        #a855f7, #d946ef, #ec4899, #f43f5e
                                                    );">
                                            </span>
                                        <span
                                          class="absolute z-2 bg-surface-0 dark:bg-surface-800 transition-all"
                                          style="inset: 1.3px; border-radius: 4px;">
                            </span>
                                        <i class="pi pi-cog z-10"></i>
                                      </button>
                                    </div>
                                </div>
                            }

                            @if (item.label === 'Versiyon') {
                                <div class="lg:hidden border-b border-t border-surface-200 cursor-pointer dark:border-surface-700 pb-2  mb-2">

                                    <div class="px-4 py-2 text-sm text-surface-600 dark:text-surface-400">
                                        v{{ version }}
                                    </div>
                                </div>
                            }

                            <!-- Normal menü öğeleri -->
                            @if (item.label !== 'Versiyon' && item.label !== 'Tema' && item.label !== 'Bildirimler' && item.label!=='Görevler') {
                                @if(item.url) {
                                    <a
                                            [href]="item.url"
                                            [target]="item.target"
                                            class="flex items-center px-4 py-3 hover:bg-surface-100 dark:hover:bg-surface-700 transition-colors">
                                        <i [class]="item.icon + ' mr-2 text-primary'"></i>
                                        <span>{{ item.label }}</span>
                                    </a>
                                } @else{

                                    <!-- İç yönlendirmeler için routerLink kullanan a etiketi -->
                                    <a
                                            [routerLink]="item.routerLink"
                                            class="flex items-center px-4 py-3 cursor-pointer hover:bg-surface-100 dark:hover:bg-surface-700 transition-colors">
                                        <i [class]="item.icon + ' mr-2 text-primary'"></i>
                                        <span>{{ item.label }}</span>
                                    </a>
                                }
                            }

                        </ng-template>
                    </p-menu>
                </div>
            </div>
        </header>

        <!-- Page Content -->
        <main class="p-6">
            <router-outlet></router-outlet>
        </main>
    </div>

    <!-- Backdrop for Mobile Sidebar -->
    <div
            class="fixed inset-0 bg-black/50 lg:hidden transition-opacity"
            [ngClass]="{'opacity-100': isSidebarOpen, 'opacity-0 pointer-events-none': !isSidebarOpen}"
            (click)="isSidebarOpen = false">
    </div>
</div>

<!-- Notifications Drawer -->
<p-drawer [(visible)]="sidebarVisible" position="right"
>
    <ng-template #headless>
        <div id="notification-drawer" class="flex flex-col p-1">

            <div class="flex items-center justify-between p-2 px-4">
                <span class="text-surface-900 dark:text-surface-0 text-xl font-medium">Bildirimler</span>
                <p-button
                        icon="pi pi-times"
                        [rounded]="true"
                        [text]="true"
                        (click)="sidebarVisible=false"

                >

                </p-button>
            </div>
            <p-tabs value="0">
                <p-tablist>
                    <p-tab value="0" class="flex-auto">
                        <div class="flex items-center gap-2 w-full justify-center">
                            <div class="relative font-bold whitespace-nowrap p-1"
                                 pBadge
                                 [badgeDisabled]="notificationCount.all === 0"
                                 badgeStyleClass="!bg-green-500"
                                 [value]="notificationCount.all"
                                 [severity]="'danger'">
                                <p class="m-0 p-0 text-center">Bildirimler</p>
                            </div>
                        </div>
                    </p-tab>
                    <p-tab value="1" class="flex-auto">
                        <div class="flex items-center gap-2 w-full justify-center">
                            <div class="font-bold whitespace-nowrap p-1"
                                 pBadge
                                 [badgeDisabled]="notificationCount.critical === 0"
                                 [value]="notificationCount.critical"
                                 [severity]="'danger'">
                                <p class="m-0 p-0 text-center">Kritik Bildirimler</p>
                            </div>
                        </div>
                    </p-tab>
                </p-tablist>

                <p-tabpanels>
                    <p-tabpanel value="0">
                        @if (notifications.length > 0) {
                            <ng-container
                                    *ngTemplateOutlet="notificationCard; context: { notifications: notifications }"></ng-container>
                        } @else {
                            <div class="col-span-12 flex flex-col justify-center items-center ">
                                <div class="h-[150px] w-[150px] flex justify-center items-center">
                                    <img src="assets/images/png/not-news.png" class="h-full w-auto" />
                                </div>
                                <div class="text-center">
                                    <h4>Bildirim Bulunamadı</h4>
                                </div>
                            </div>
                        }
                    </p-tabpanel>

                    <p-tabpanel value="1">
                        @if (criticalNotifications.length > 0) {
                            <ng-container
                                    *ngTemplateOutlet="notificationCard; context: { notifications: criticalNotifications }"></ng-container>
                        } @else {
                            <div class="col-span-12 flex flex-col justify-center items-center ">
                                <div class="h-[150px] w-[150px] flex justify-center items-center">
                                    <img src="assets/images/png/not-news.png" class="h-full w-auto" />
                                </div>
                                <div class="text-center">
                                    <h4>Kritik Bildirim Bulunamadı</h4>
                                </div>
                            </div>
                        }
                    </p-tabpanel>
                </p-tabpanels>
            </p-tabs>


        </div>

        <div class="w-full px-6 absolute bottom-0 right-0 pt-2 dark:bg-surface-950">
            <div class="pt-2 border-t border-surface"
                 style="margin-right:5px"
            >
                <div class=" mb-4 flex justify-center items-center gap-2 ">

                    <p-button

                            routerLink="/notifications"

                            [label]="'Tüm Bildirimler' "
                    ></p-button>

                    <p-button
                            [outlined]="true"

                            (click)="bulkNotificationReading()"
                            [label]="'Tümünü Okundu Olarak İşaretle' "

                    ></p-button>


                </div>
            </div>


        </div>


    </ng-template>
</p-drawer>

<ng-template #notificationCard let-notifications="notifications">
    <ul class="list-none p-0 -m-2" style="max-height:calc(100vh - 200px);overflow-y:scroll">
        @for (notification of notifications; track $index) {
            <li class="py-2  rounded-border mb-2  "
                [ngClass]="$even ? 'surface-100 dark:bg-surface-700' : 'border dark:border-2 border-black/10 dark:border-surface-800'">

                <div>
                    <div class="flex">
                        <div class="p-2">
                            <div class="text-surface-900 dark:text-surface-0 font-bold mb-2">{{ notification.Title }}</div>
                            <div class="text-surface-600 dark:text-surface-200 font-medium block mb-2"
                                 [innerHTML]="notification.Description.length > 148 ? notification.Description.slice(0, 148) + '...' : notification.Description">
                            </div>


                        </div>
                        <div class="flex justify-center items-center px-1 mr-1">
          <span
                  class="font-semibold cursor-pointer"
                  (click)="notificationDetailDialog(notification.Id,notification.Type)">Detay</span>
                        </div>


                    </div>

                    <div class="flex justify-between items-center px-2">
                        <p-chip styleClass="pl-0 !pr-4">
                    <span
                            [ngClass]="notification.Type | notificationTypeColor"
                            class="text-white rounded-full w-8 h-8 text-sm flex items-center justify-center">
                        {{ (notification.Type | notificationType)?.slice(0, 1) }}
                    </span>
                            <span class="ml-2 font-medium w-max flex whitespace-nowrap text-sm flex-nowrap">
                        {{ notification.Type | notificationType }}
                    </span>
                        </p-chip>
                        <span
                                class="block text-surface-500 dark:text-surface-300 font-medium ml-16 md:ml-8 mt-2 md:mt-0">{{ notification.CreatedOn | date: "dd.MM.yyyy" }}</span>
                    </div>

                </div>

            </li>
        }
    </ul>

</ng-template>


<magenty-change-theme
  [(menuVisible)]="menuVisible"
  [(mainsSidebarItems)]="mainsSidebarItems"
                       [(otherSidebarItems)]="otherSidebarItems"
                       [(favoriteSidebarItems)]="favoriteSidebarItems"
                       [(activeUniqProviderList)]="activeUniqProviderList"
                       (menuRefresh)="menuRefresh=$event"></magenty-change-theme>


