import { Route } from '@angular/router';

export const reportsRouters: Route[] = [
  {
    path: '',
    title: 'Genel Rapor',
    pathMatch: 'full',
    redirectTo: 'main',
  },
  {
    path: 'main',
    title: 'Genel Rapor',
    loadComponent: () =>
      import('./base/base-reports.component').then(
        (m) => m.BaseReportsComponent
      ),
  },
  {
    path: 'product',
    title: 'Ürün Raporu',
    loadComponent: () =>
      import('./product/product-reports.component').then(
        (m) => m.ProductReportsComponent
      ),
  },
];
