import { Route } from '@angular/router';

export const marketplacesRouters: Route[] = [
  {
    path: 'dashboard/:providerId',
    title: 'Pazaryeri Anasayfa',
    loadComponent: () =>
      import('./dashboard/marketplace-dashboard.component').then(
        (m) => m.MarketplaceDashboardComponent
      ),
  },
  {
    path: 'price-template/:providerId',
    title: 'Fiyat Şablonu',
    loadComponent: () =>
      import(
        './price/price-template/marketplace-price-template.component'
      ).then((m) => m.MarketplacePriceTemplateComponent),
  },
  {
    path: 'price-transactions/:providerId',
    title: 'Fiyat İşlemleri',
    loadComponent: () =>
      import(
        './price/price-transactions/marketplace-price-transactions.component'
      ).then((m) => m.MarketplacePriceTransactionsComponent),
  },

  {
    path: 'shipping-template/:providerId',
    title: 'Kargo Şablonu',
    loadComponent: () =>
      import('./shipping/marketplace-shipping-template.component').then(
        (m) => m.MarketplaceShippingTemplateComponent
      ),
  },
  {
    path: 'buybox/:providerId',
    title: 'Buy-Box',
    loadComponent: () =>
      import('./buy-box/marketplace-buybox.component').then(
        (m) => m.MarketplaceBuyboxComponent
      ),
  },
  {
    path: 'marketplace-product-server/:providerId',
    title: 'Pazaryeri Ürünlerimiz-server',
    loadComponent: () =>
      import('./product/server/marketplace-product-server.component').then(
        (m) => m.MarketplaceProductServerComponent
      ),
  },
  {
    path: 'marketplace-product-local/:providerId',
    title: 'Pazaryeri Ürünlerimiz-local',
    loadComponent: () =>
      import('./product/local/marketplace-product-local.component').then(
        (m) => m.MarketplaceProductLocalComponent
      ),
  },
  {
    path: 'bulk-product-addition/:providerId',
    title: 'Pazaryeri Ürün Ekleme',
    loadComponent: () =>
      import(
        '../../components/bulk-product-addition/main/main-bulk-product-addition.component'
      ).then((m) => m.MainBulkProductAdditionComponent),
  },
];
