import { TuiRootModule } from '@taiga-ui/core';
import {
  APP_INITIALIZER,
  ApplicationConfig,
  ErrorHandler,
  importProvidersFrom,
  inject,
  provideAppInitializer,
} from '@angular/core';
import { provideRouter } from '@angular/router';
import { appRoutes } from './app.routes';
import { provideClientHydration } from '@angular/platform-browser';
import {
  provideHttpClient,
  withFetch,
  withInterceptors,
} from '@angular/common/http';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { jwtInterceptor } from './core/interceptors/jwt/jwt.interceptor';
import { providePrimeNG } from 'primeng/config';
import Lara from '@primeng/themes/lara';
import LogRocket from 'logrocket';
import { environment } from '../environments/environment';
import { ErrorHandlerService } from './core/services/logrocket/error-handler.service';
import * as Sentry from "@sentry/angular";
import { Router } from '@angular/router';
import { TraceService } from '@sentry/angular';

export const appConfig: ApplicationConfig = {
  providers: [
    provideAnimationsAsync(),
    providePrimeNG({
      theme: {
        preset: Lara,
        options: {
          darkModeSelector: '.dark',
        },
      },
    }),
    provideClientHydration(),
    provideRouter(appRoutes),
    provideHttpClient(withFetch(), withInterceptors([jwtInterceptor])),

    importProvidersFrom(TuiRootModule),

    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    provideAppInitializer(() => {
      inject(TraceService);
    }),




    {
      provide: ErrorHandler,
      useClass: ErrorHandlerService,
    },
  ],
};
