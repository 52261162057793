export const PrimngTurkishI18n = {
  startsWith: 'Başlayan',
  contains: 'İçeren',
  notContains: 'İçermeyen',
  endsWith: 'Biten',
  equals: 'Eşit',
  notEquals: 'Eşit Olmayan',
  noFilter: 'Filtre Yok',
  lt: 'Küçüktür',
  lte: 'Küçük veya Eşit',
  gt: 'Büyüktür',
  gte: 'Büyük veya Eşit',
  is: 'İse',
  isNot: 'Değildir',
  before: 'Önce',
  after: 'Sonra',
  dateIs: 'Tarih Şudur',
  dateIsNot: 'Tarih Şu Değildir',
  dateBefore: 'Tarih Şu Tarihten Öncedir',
  dateAfter: 'Tarih Şu Tarihten Sonradır',
  clear: 'Temizle',
  apply: 'Uygula',
  matchAll: 'Hepsini Eşleştir',
  matchAny: 'Herhangi Birini Eşleştir',
  addRule: 'Kural Ekle',
  removeRule: 'Kuralı Kaldır',
  accept: 'Evet',
  reject: 'Hayır',
  choose: 'Seç',
  upload: 'Yükle',
  cancel: 'İptal',
  dayNames: [
    'Pazar',
    'Pazartesi',
    'Salı',
    'Çarşamba',
    'Perşembe',
    'Cuma',
    'Cumartesi',
  ],
  dayNamesShort: ['Paz', 'Pzt', 'Sal', 'Çar', 'Per', 'Cum', 'Cmt'],
  dayNamesMin: ['Pa', 'Pt', 'Sa', 'Ça', 'Pe', 'Cu', 'Ct'],
  monthNames: [
    'Ocak',
    'Şubat',
    'Mart',
    'Nisan',
    'Mayıs',
    'Haziran',
    'Temmuz',
    'Ağustos',
    'Eylül',
    'Ekim',
    'Kasım',
    'Aralık',
  ],
  monthNamesShort: [
    'Oca',
    'Şub',
    'Mar',
    'Nis',
    'May',
    'Haz',
    'Tem',
    'Ağu',
    'Eyl',
    'Eki',
    'Kas',
    'Ara',
  ],
  dateFormat: 'gg/aa/yyyy',
  firstDayOfWeek: 1,
  today: 'Bugün',
  weekHeader: 'Hf',
  weak: 'Zayıf',
  medium: 'Orta',
  strong: 'Güçlü',
  passwordPrompt: 'Şifre girin',
  emptyMessage: 'Sonuç bulunamadı',
  emptyFilterMessage: 'Filtre sonucunda sonuç bulunamadı',
};
