import { provideRouter, Route } from '@angular/router';
import { transferRouters } from './transfer/transfer-routers';

export const catalogRouters: Route[] = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'products',
  },
  {
    path: 'products',
    pathMatch: 'full',
    title: 'Ürünler',
    loadComponent: () =>
      import('./products/products.component').then((m) => m.ProductsComponent),
  },
  {
    path: 'categories',
    title: 'Kategoriler',
    loadComponent: () =>
      import('./categories/categories.component').then(
        (m) => m.CategoriesComponent
      ),
  },
  {
    path: 'brands',
    title: 'Markalar',
    loadComponent: () =>
      import('./brands/brands.component').then((m) => m.BrandsComponent),
  },
  {
    path: 'product-variants-list',
    title: 'Varyantlar',
    loadComponent: () =>
      import(
        './variants/product-variant-list/product-variant-list.component'
      ).then((m) => m.ProductVariantListComponent),
  },
  {
    path: 'variant-templates',
    title: 'Varyantlar',
    loadComponent: () =>
      import('./variants/variant-templates/variant-templates.component').then(
        (m) => m.VariantTemplatesComponent
      ),
  },
  {
    path: 'variants',
    title: 'Varyantlar',
    loadComponent: () =>
      import('./variants/variants/variants.component').then(
        (m) => m.VariantsComponent
      ),
  },

  {
    path: 'transfer',
    title: 'Aktarım',
    children: transferRouters,
    providers: [provideRouter(transferRouters)],
    loadComponent: () =>
      import('./transfer/main-transfer/main-transfer.component').then(
        (m) => m.MainTransferComponent
      ),
  },
  {
    path: 'transfer/excel/:id',
    title: 'Excel Aktarım',
    loadComponent: () =>
      import(
        '../../components/transfer/detail/main/main-transfer-detail.component'
      ).then((m) => m.MainTransferDetailComponent),
  },
  {
    path: 'transfer/xml/:id',
    title: 'XML Aktarım',
    loadComponent: () =>
      import(
        '../../components/transfer/xml-detail/main/xml-transfer-main.component'
      ).then((m) => m.XmlTransferMainComponent),
  },

  {
    path: 'transfer/excel-template',
    pathMatch: 'full',
    title: 'Excel Şablonları',
    loadComponent: () =>
      import('./transfer/excel-template/excel-template.component').then(
        (m) => m.ExcelTemplateComponent
      ),
  },
];
