import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from './app/app.component';

import LogRocket from 'logrocket';
import { appConfig } from './app/app.config';
import { environment } from './environments/environment';
import Clarity from '@microsoft/clarity';
import * as Sentry from "@sentry/angular";
import { EnvironmentTypeEnum } from './app/core/enums/enums/environment-enum';
import packageInfo, { version } from '@packageJson';

if (environment.chatBot) {

  Clarity.init('q8cvqu7b41');

Sentry.init({
  dsn: "https://4b703b6650feb6ebceef46ee78ec6cf0@o4508864422150144.ingest.de.sentry.io/4508864441090128",
  release:version,

  integrations: [
  

    Sentry.replayIntegration({
      maskAllText: false, 
      blockAllMedia: false,
      networkDetailAllowUrls: [
        'https://api.tekviyaservice.com'// Tüm API endpointlerini kapsar
      ],
      networkCaptureBodies: true,
      networkRequestHeaders: ['*'], // Tüm request headerları
      networkResponseHeaders: ['*'] // Tüm response headerları
    }),
    Sentry.browserTracingIntegration(),
    Sentry.browserProfilingIntegration(),
  ],
    // Session Replay
    tracePropagationTargets: [
      'https://api.tekviyaservice.com/api/v1',
      /^https:\/\/api\.tekviyaservice\.com/,  // Tüm API endpointleri için
      'localhost',
      /^\/api/  // Relative API paths için
    ],

    beforeSend: (event) => {
      const ignoredErrors = [
        'Failed to fetch dynamically imported module',
        
      ];
      if (event.message && ignoredErrors.some(err => event?.message?.includes(err))) {
        return null;
      }
  
      return event;
    },
    

    
     tracesSampleRate: 1,
  profilesSampleRate: 1,
 /* tracesSampleRate: 1,
  profilesSampleRate:1,*/
    replaysSessionSampleRate: 1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

}
bootstrapApplication(AppComponent, appConfig).catch((err) =>
  console.error(err)
);
