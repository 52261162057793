import { Route } from '@angular/router';

export const tasksRouters: Route[] = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'main',
  },
  {
    path: 'main',
    pathMatch: 'full',
    title: 'İşlemler',
    loadComponent: () =>
      import('./main/main-tasks.component').then((m) => m.MainTasksComponent),
  },
  {
    path: 'errors',
    pathMatch: 'full',
    title: 'Hatalar',
    loadComponent: () =>
      import('../errors/main/main-errors.component').then(
        (m) => m.MainErrorsComponent
      ),
  },
];
