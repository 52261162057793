import { provideRouter, Route } from '@angular/router';
import { jwtTokenGuard } from './core/guard/jwt-token.guard';
import { MainComponent } from './layout/main/main.component';
import { panelSettingsRouters } from './layout/panel-settings/panel-settings-routers';
import { catalogRouters } from './layout/catalog/catalog-routers';
import { definitionsRouters } from './layout/definitions/definitions-routers';
import { productDetailRouters } from './components/products/detail/product-detail-routers';
import { marketplacesRouters } from './layout/marketplaces/marketplaces-routers';
import { salesRouters } from './layout/sales/sales-routers';
import { profileRouter } from './layout/profile/profile-routers';
import { purchaseGuard } from './core/guard/purchase.guard';
import { purchaseRouters } from './layout/purchase/purchase-routers';
import { reportsRouters } from './layout/reports/reports-routers';
import { notificationsRouters } from './layout/notifications/notifications-routers';
import { noTokensGuard } from './core/guard/no-tokens.guard';
import { companyGuard } from './core/guard/company.guard';
import { definitionsAuthRouters } from './components/definitions/auth/definitions-auth-routers';
import { tasksRouters } from './layout/tasks/tasks-routers';

export const appRoutes: Route[] = [
  {
    path: '',
    canActivate: [jwtTokenGuard, purchaseGuard],
    component: MainComponent,
    providers: [],
    children: [
      {
        path: '',
        pathMatch: 'full',
        title: 'Anasayfa',
        redirectTo: 'dashboard',
      },
      {
        path: 'dashboard',
        title: 'Anasayfa',
        loadComponent: () =>
          import('./layout/dashboard/main/main-dashboard.component').then(
            (m) => m.MainDashboardComponent
          ),
      },
      {
        path: 'blog',
        title: 'Blog',
        loadComponent: () =>
          import('./layout/blog/main/main-blog.component').then(
            (m) => m.MainBlogComponent
          ),
      },
      {
        path: 'panel-settings',
        providers: [provideRouter(panelSettingsRouters)],
        children: panelSettingsRouters,
        title: 'Panel Ayarları',
        loadComponent: () =>
          import(
            './layout/panel-settings/main/main-panel-settings.component'
          ).then((m) => m.MainPanelSettingsComponent),
      },
      {
        path: 'catalog',
        children: catalogRouters,
        providers: [provideRouter(catalogRouters)],
        title: 'Katalog',
        loadComponent: () =>
          import('./layout/catalog/main/main-catalog.component').then(
            (m) => m.MainCatalogComponent
          ),
      },
      {
        path: 'definitions',
        children: definitionsRouters,
        providers: [provideRouter(definitionsRouters)],
        title: 'Tanımlar',
        loadComponent: () =>
          import('./layout/definitions/main/main-definitions.component').then(
            (m) => m.MainDefinitionsComponent
          ),
      },
      {
        path: 'tasks',
        title: 'Görevler',
        children: tasksRouters,
        providers: [provideRouter(tasksRouters)],
      },
      {
        path: 'product',
        children: productDetailRouters,
        providers: [provideRouter(productDetailRouters)],
        title: 'Ürün Detayı',
      },
      {
        path: 'marketplaces',
        children: marketplacesRouters,
        providers: [provideRouter(marketplacesRouters)],
        title: 'Pazaryeri',
        loadComponent: () =>
          import('./layout/marketplaces/main/main-marketplaces.component').then(
            (m) => m.MainMarketplacesComponent
          ),
      },
      {
        path: 'sales',
        children: salesRouters,
        providers: [provideRouter(salesRouters)],
        title: 'Satışlar',
        loadComponent: () =>
          import('./layout/sales/main/main-sales.component').then(
            (m) => m.MainSalesComponent
          ),
      },
      {
        path: 'profile',
        children: profileRouter,
        providers: [provideRouter(profileRouter)],
        title: 'Profil',
        loadComponent: () =>
          import('./layout/profile/main/main-profile.component').then(
            (m) => m.MainProfileComponent
          ),
      },
      {
        path: 'reports',
        children: reportsRouters,
        providers: [provideRouter(reportsRouters)],
        title: 'Raporlar',
        loadComponent: () =>
          import('./layout/reports/main/main-reports.component').then(
            (m) => m.MainReportsComponent
          ),
      },
      {
        path: 'documentation',
        title: 'Bilgi Bankası',
        loadComponent: () =>
          import(
            './layout/documentation/main/main-documentation.component'
          ).then((m) => m.MainDocumentationComponent),
      },
      {
        path: 'changelog',
        title: 'Değişlikler',
        loadComponent: () =>
          import('./layout/changelog/changelog-main.component').then(
            (m) => m.ChangelogMainComponent
          ),
      },
      {
        path: 'notifications',
        children: notificationsRouters,
        providers: [provideRouter(notificationsRouters)],
        title: 'Bildirimler',
        loadComponent: () =>
          import(
            './layout/notifications/main/main-notifications.component'
          ).then((m) => m.MainNotificationsComponent),
      },
    ],
  },
  {
    path: 'auth',
    canActivate: [jwtTokenGuard],
    providers: [provideRouter(definitionsAuthRouters)],
    children: definitionsAuthRouters,
    title: 'Auth',
  },
  {
    path: 'purchase',
    canActivate: [jwtTokenGuard],
    providers: [provideRouter(purchaseRouters)],
    children: purchaseRouters,
    title: 'Ödeme',
    loadComponent: () =>
      import('./layout/purchase/main/main-purchase.component').then(
        (m) => m.MainPurchaseComponent
      ),
  },
  {
    path: 'changeToken/:token',
    title: 'Token Değiştirme',
    loadComponent: () =>
      import('./components/change-token/change-token.component').then(
        (m) => m.ChangeTokenComponent
      ),
  },

  {
    path: 'signin',
    canActivate: [noTokensGuard],
    loadComponent: () =>
      import('./core/componenets/signin/signin.component').then(
        (m) => m.SigninComponent
      ),
  },
  {
    path: 'signup',
    canActivate: [noTokensGuard],
    loadComponent: () =>
      import('./core/componenets/signup/signup.component').then(
        (m) => m.SignupComponent
      ),
  },
  {
    path: 'signup/:wordpress',
    canActivate: [noTokensGuard],
    loadComponent: () =>
      import('./core/componenets/signup/signup.component').then(
        (m) => m.SignupComponent
      ),
  },
  {
    path: 'authentication',
    canActivate: [noTokensGuard],
    children: [
      {
        path: 'signup/:wordpress',
        canActivate: [noTokensGuard],
        loadComponent: () =>
          import('./core/componenets/signup/signup.component').then(
            (m) => m.SignupComponent
          ),
      },
      {
        path: 'signup',
        canActivate: [noTokensGuard],
        loadComponent: () =>
          import('./core/componenets/signup/signup.component').then(
            (m) => m.SignupComponent
          ),
      },
    ],
  },
  {
    path: 'access-email/:email-access',
    canActivate: [noTokensGuard],
    loadComponent: () =>
      import('./core/componenets/email-access/email-access.component').then(
        (m) => m.EmailAccessComponent
      ),
  },

  {
    path: '**',

    loadComponent: () =>
      import('./core/componenets/not-found/not-found.component').then(
        (m) => m.NotFoundComponent
      ),
  },
];
