import { TuiRootModule } from '@taiga-ui/core';
import { Component, inject, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ConfirmDialogComponent } from '@component-library';
import { ErrorMessageComponent } from './components/message/error/error-message.component';
import { InfoMessageComponent } from './components/message/info/info-message.component';
import { ConfirmationService, MessageService } from 'primeng/api';
import { GlobalMessageService } from './core/services/global-services/global-message-service/global-message.service';
import { GlobalConfirmationService } from './core/services/global-services/global-confirmation-service/global-confirmation.service';
import { DatabaseService } from './core/database/database_service';
import { ThemeService } from './core/services/theme/theme.service';
import { environment } from '../environments/environment';

@Component({
  imports: [
    RouterModule,
    TuiRootModule,
    ConfirmDialogComponent,
    ErrorMessageComponent,
    InfoMessageComponent,
  ],
  providers: [ConfirmationService, MessageService],
  selector: 'magenty-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
  confirmationService = inject(ConfirmationService);
  messageService = inject(MessageService);

  globalMessageService = inject(GlobalMessageService);
  globalConfirmationService = inject(GlobalConfirmationService);

  title = 'magenty-ui ';

  constructor(private themeService: ThemeService) {
    this.globalConfirmationService.set(this.confirmationService);
    this.globalMessageService.set(this.messageService);
    this.themeService.getThemeConfig();
  }

  // InfosetChat için gerekli kod
  private initializeInfosetChat(): void {
    const t = window as any;

    if (typeof t.InfosetChat !== 'function') {
      const n = document;

      const e: any = function (...args: any[]) {
        e.c(args);
      };
      e.q = [];
      e.c = function (t: any) {
        e.q.push(t);
      };

      t.InfosetChat = e;

      const a = () => {
        const script = n.createElement('script') as HTMLScriptElement;
        script.type = 'text/javascript';
        script.async = true;
        script.src = 'https://cdn.infoset.app/chat/icw.js';

        const firstScript = n.getElementsByTagName('script')[0];
        if (firstScript.parentNode) {
          firstScript.parentNode.insertBefore(script, firstScript);
        }
      };

      if ('attachEvent' in t) {
        t.attachEvent('onload', a);
      } else if ('addEventListener' in t) {
        t.addEventListener('load', a, false);
      }
    }
  }

  // Angular yaşam döngüsü - initializeInfosetChat burada çağrılacak
  ngOnInit(): void {
    if (environment.chatBot) {
      this.initializeInfosetChat();
    }
  }
}
