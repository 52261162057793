import { Route } from '@angular/router';

export const transferRouters: Route[] = [
  {
    path: '',
    pathMatch: 'full',
    title: 'Aktarım',
    loadComponent: () =>
      import('./base-transfer/base-transfer.component').then(
        (m) => m.BaseTransferComponent
      ),
  },
  {
    path: 'excel-template',
    pathMatch: 'full',
    title: 'Excel Şablonları',
    loadComponent: () =>
      import('./excel-template/excel-template.component').then(
        (m) => m.ExcelTemplateComponent
      ),
  },
];
