import { Route } from '@angular/router';

export const productDetailRouters: Route[] = [
  {
    path: 'type-selection',
    title: 'Ürün Tipi Seçimi',
    loadComponent: () =>
      import('./type-selection/product-type-selection.component').then(
        (m) => m.ProductTypeSelectionComponent
      ),
  },
  {
    path: 'detail/:Id',
    title: 'Ürün Detayı',
    loadComponent: () =>
      import('./product-features/main/main-product-features.component').then(
        (m) => m.MainProductFeaturesComponent
      ),
  },
];
