import { Route } from '@angular/router';

export const panelSettingsRouters: Route[] = [
  {
    path: '',
    pathMatch: 'full',
    title: 'Temel Ayarlar',
    data: { activeLeft: 0, activeBody: 0 },
    loadComponent: () =>
      import('./base-setting/panel-settings-base.component').then(
        (m) => m.PanelSettingsBaseComponent
      ),
  },
  {
    path: 'integration',
    title: 'Entegrasyon Ayarları',
    data: { activeLeft: 1, activeBody: 0 },
    loadComponent: () =>
      import('./integration-setting/panel-settings-integration.component').then(
        (m) => m.PanelSettingsIntegrationComponent
      ),
  },
  {
    path: 'price',
    title: 'Fiyat Ayarları',
    data: { activeLeft: 2, activeBody: 0 },
    loadComponent: () =>
      import('./price-setting/panel-settings-price.component').then(
        (m) => m.PanelSettingsPriceComponent
      ),
  },
  {
    path: 'invoice',
    title: 'Fatura Ayarları',
    data: { activeLeft: 3, activeBody: 0 },
    loadComponent: () =>
      import('./invoice-setting/panel-settings-invoice.component').then(
        (m) => m.PanelSettingsInvoiceComponent
      ),
  },
  {
    path: 'notification',
    title: 'Bildirim Ayarları',
    data: { activeLeft: 4, activeBody: 0 },
    loadComponent: () =>
      import(
        './notification-setting/panel-settings-notification.component'
      ).then((m) => m.PanelSettingsNotificationComponent),
  },
  {
    path: 'currency',
    title: 'Döviz Ayarları',
    data: { activeLeft: 5, activeBody: 0 },
    loadComponent: () =>
      import('./currency-setting/panel-settings-currency.component').then(
        (m) => m.PanelSettingsCurrencyComponent
      ),
  },
  {
    path: 'barcode-design',
    title: 'Barkod Tasarım',
    data: { activeLeft: 6, activeBody: 1 },
    loadComponent: () =>
      import(
        './barcode-design-setting/panel-settings-barcode-design.component'
      ).then((m) => m.PanelSettingsBarcodeDesignComponent),
  },
  {
    path: 'buy-box',
    title: 'Buy-Box Ayarları',
    data: { activeLeft: 7, activeBody: 0 },
    loadComponent: () =>
      import(
        './buy-box-setting/buy-box-setting.component'
      ).then((m) => m.BuyBoxSettingComponent),
  },
];
