<p-toast key="infoMessage"   styleClass="w-[11rem]  md:w-[30rem] " [preventOpenDuplicates]="true">
    <ng-template let-message #message>
        <div class="flex items-start flex-1">
            <i class="pi pi-envelope text-blue-500 text-2xl mr-4"></i>
            <div>
                <span class="text-xl font-medium text-surface-900 dark:text-surface-0">{{ message.summary }}</span>
                <p class="text-surface-700 dark:text-surface-100 mt-4">{{ message.detail }}</p>
            </div>
        </div>
    </ng-template>
</p-toast>