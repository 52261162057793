import { Route } from '@angular/router';

export const definitionsAuthRouters: Route[] = [
  {
    path: 'amazon',
    title: 'Amazon',
    loadComponent: () =>
      import('./amazon/auth-amazon.component').then(
        (m) => m.AuthAmazonComponent
      ),
  },
  {
    path: 'ideasoft',
    title: 'İdeaSoft',
    loadComponent: () =>
      import('./ideasoft/auth-ideasoft.component').then(
        (m) => m.AuthIdeasoftComponent
      ),
  },
];
