import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { environment } from '../../../../environments/environment';

import { StoreService } from '../../store/store.service';
import * as Sentry from '@sentry/angular';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService implements ErrorHandler {
  constructor(private injector: Injector) {}

  handleError(err: any): void {
    if (err?.status && err.status >= 400) {
      console.log('Hata: Lütfen önce giriş yapınız.');
    } else {
      if (environment.chatBot) {
        if ( err?.message)
        {
            if (!(err?.message.toString().includes('dynamically imported module')))
            {
              Sentry.captureException(err);
            }

        }else {
          Sentry.captureException(err);
        }
      }
      console.error(err);
    }
  }

  private getUserInfo(): string {
    try {
      const store = this.injector.get(StoreService);
      const company = store.store$()?.company?.base;
      return `${company?.Name ?? ''} ${company?.Surname ?? ''}`.trim();
    } catch {
      return '';
    }
  }
}

/*
{
  "headers": {
  "headers": {},
  "normalizedNames": {},
  "lazyUpdate": null
},
  "status": 401,
  "statusText": "Unknown Error",
  "url": "https://api-dev.tekviyaservice.com/api/v1/providerProducts/getSellingProductCountByProvider",
  "ok": false,
  "name": "HttpErrorResponse",
  "message": "Http failure response for https://api-dev.tekviyaservice.com/api/v1/providerProducts/getSellingProductCountByProvider: 401 ",
  "error": {}
}*/
