<p-drawer [(visible)]="sidebarVisible" position="right" styleClass="task-drawer">
  <ng-template #headless>
    <div id="task-drawer" class="flex flex-col h-full bg-surface-0 dark:bg-surface-900">
      <!-- Header -->
      <div class="flex flex-col p-4">
        <!-- Üst Başlık Bölümü -->
        <div class="flex items-center justify-between mb-4 pb-4 border-b border-surface-200 dark:border-surface-700">
          <div class="flex items-center gap-3">
            <i class="pi pi-tasks text-xl text-primary-500"></i>
            <span class="text-xl font-semibold text-surface-900 dark:text-surface-0">Görevler</span>
          </div>
          <p-button
            icon="pi pi-times"
            severity="secondary"
            [text]="true"
            [rounded]="true"
            (click)="sidebarVisible.set(false)">
          </p-button>
        </div>

        <!-- Alt Kontrol Bölümü -->
        <div class="flex flex-col lg:flex-row gap-2 justify-between p-2">
          <div class="flex  items-center justify-between w-full lg:flex-1 gap-3 bg-surface-50 dark:bg-surface-800 px-2 py-4 rounded-lg">
          <div class="flex items-center gap-2 ">
            <i class="pi pi-bell text-lg text-surface-600 dark:text-surface-400"></i>
            <label class="font-medium text-surface-700 dark:text-surface-200">Bildirimler</label>
          </div>

          <div class="flex items-center gap-2">
            <p-toggleswitch   class="-mb-2"
                              styleClass="scale-90"
                              [(ngModel)]="openNotification"
                              (ngModelChange)="saveDb()">
              <ng-template #handle let-checked="checked">
                <i [ngClass]="['!text-xs', 'pi', checked ? 'pi-check' : 'pi-times']"></i>
              </ng-template>
            </p-toggleswitch>

          </div>
        </div>


          <!-- Zamanlayıcı ve Yenileme -->
          <div class="flex items-center gap-3 bg-surface-50 lg:flex-1 dark:bg-surface-800 p-2 rounded-lg">
            <div class="flex items-center gap-2 w-full lg:w-auto">
              <i class="pi pi-clock text-lg text-surface-600 dark:text-surface-400"></i>
              <span class="font-medium text-surface-600 dark:text-surface-400" style="text-wrap: nowrap;">
        Yenileme: <span class="text-primary-500">{{timeout}}</span> sn
      </span>
            </div>

            <div class="flex gap-1">
              <p-button
                [icon]="timerActive ? 'pi pi-pause' : 'pi pi-play'"
                [severity]="timerActive ? 'warn' : 'success'"
                [text]="true"
                [rounded]="true"
                [pTooltip]="timerActive ? 'Duraklat' : 'Başlat'"
                tooltipPosition="top"
                (onClick)="timerActive ? stopTimer() : startTimer()">
              </p-button>

              <p-button
                icon="pi pi-refresh"
                [text]="true"
                [rounded]="true"
                severity="info"
                pTooltip="Yenile"
                tooltipPosition="top"
                (onClick)="getTasks()">
              </p-button>
            </div>
          </div>

        </div>
      </div>


      <!-- Content -->
      <div class="flex-1  overflow-auto">
        <!-- Table Header -->
        <div class="sticky top-0 z-10 bg-surface-50 dark:bg-surface-950 shadow-sm !max-w-100">
          <div class="grid grid-cols-12 gap-2 p-4 font-medium text-surface-900 dark:text-surface-0">
            <div class="col-span-4 text-center">Görev</div>
            <div class="col-span-2 text-center">P</div>
            <div class="col-span-2 text-center">Durumu</div>
            <div class="col-span-4 text-center">Detay</div>
          </div>
        </div>

        <!-- Table Body -->
        <div class="p-2">
          @for(task of tasks; track $index) {
            <div
                    [id]="task.QuestType===QuestTypeEnum.DownloadProviderProduct ? 'download-task':''"
                    class="grid grid-cols-12 gap-2 p-3 mb-2 rounded-lg transition-all duration-200
                        hover:bg-surface-100 dark:hover:bg-surface-800
                        {{$index % 2 === 0 ? 'bg-surface-50' : 'bg-surface-0'}}
                        dark:{{$index % 2 === 0 ? 'bg-surface-900' : 'bg-surface-800'}}">

              <!-- Görev Tipi -->
              <div class="col-span-4 flex items-center justify-center text-surface-900 dark:text-surface-0">
                <span class="text-center">{{task.QuestType | questType}}</span>
              </div>

              <!-- Pazaryeri -->
              <div class="col-span-2 flex justify-center items-center">
                <tui-avatar
                  [autoColor]="true"
                  [avatarUrl]="task.Identity | providerIcon"
                  class="cursor-pointer bg-surface-0 dark:bg-surface-950"
                  size="m">
                </tui-avatar>
              </div>

              <!-- Durum -->
              <div class="col-span-2 flex justify-center">
                <p-chip styleClass="!bg-transparent">
                  <span
                    [pTooltip]="task.Status | monitorStatus"
                    [ngClass]="task.Status | monitorStatusColor"
                    class="text-white cursor-pointer rounded-full w-8 h-8 flex items-center justify-center">
                    {{ (task.Status | monitorStatus).slice(0, 1) }}
                  </span>

                </p-chip>
              </div>

              <!-- Detay -->
              <div class="col-span-4 flex items-center justify-center">
                @if (task.Items?.length === 0 || !task.Items) {
                  <span class="text-surface-600 text-center dark:text-surface-400">Detay Yok</span>
                } @else {
                  @let completedCount=(task.Items | monitorItems: 1);
                  @let notCompletedCount=(task.Items | monitorItems: 2);
                  @let inProcess=(task.Items | monitorItems: 3);

                  <div class="flex gap-2 justify-center items-center">
                    <div class="flex flex-col gap-2 justify-center items-center">
                  @if (completedCount > 0) {
                      <p-chip styleClass="pl-0 pr-4 dark:bg-surface-950 cursor-pointer">
                        <span

                          class="text-white bg-green-500 dark:bg-green-600 rounded-full w-8 h-8 flex items-center justify-center">
                          B
                        </span>
                        <span
                          class="ml-2 font-medium w-max flex whitespace-nowrap flex-nowrap pr-3  ">{{ task.Items?.length + ' / ' + (task.Items | monitorItems: 1) }}</span>
                      </p-chip>
                    }
                    @if(notCompletedCount > 0) {
                      <p-chip styleClass="pl-0 pr-4 dark:bg-surface-950 cursor-pointer">
                        <span

                          [pTooltip]="task.Items?.length + ' / ' + (task.Items | monitorItems: 2)"
                          class="text-white bg-red-500 dark:bg-red-600 rounded-full w-8 h-8 flex items-center justify-center">
                          H
                        </span>
                        <span
                          class="ml-2 font-medium w-max flex whitespace-nowrap flex-nowrap pr-3  ">{{ task.Items?.length + ' / ' + (task.Items | monitorItems: 2) }}</span>
                      </p-chip>
                    }
                    @if(inProcess > 0) {
                      <p-chip styleClass="pl-0 pr-4 dark:bg-surface-950 cursor-pointer">
                        <span
                          class="text-white bg-yellow-500 dark:bg-yellow-600 rounded-full w-8 h-8 flex items-center justify-center">
                          I
                        </span>
                        <span
                          class="ml-2 font-medium w-max flex whitespace-nowrap flex-nowrap pr-3  ">{{ task.Items?.length + ' / ' + (task.Items | monitorItems: 3 )}}</span>
                      </p-chip>
                    }
                    </div>
                    @if(task.Items && task.Items.length > 0) {
                      <p-button
                        [text]="true"
                        [rounded]="true"
                        icon="pi pi-eye"
                        (onClick)="openDetails(task)">
                      </p-button>
                    }

                  </div>
                }
              </div>

            </div>
          }
        </div>
      </div>

      <!-- Footer -->
      <div class="p-4 border-t border-surface-200 dark:border-surface-700 bg-surface-0 dark:bg-surface-900">
        <div class="flex items-center justify-between gap-4">
          <a class="flex-1" routerLink="/tasks/main" >
            <p-button
              class="w-full"
              label="Tüm Görevler"

              (onClick)="sidebarVisible.set(false)"
              styleClass="w-full"
            >
            </p-button>
          </a>
          <a class="flex-1" routerLink="/tasks/errors">
            <p-button
              class="w-full"
              label="Tüm Hatalar"

              (onClick)="sidebarVisible.set(false)"
              styleClass="w-full"
            >
            </p-button>
          </a>

        </div>

      </div>
    </div>
  </ng-template>
</p-drawer>

<!-- Task Detail Dialog -->
<p-dialog
  [(visible)]="taskDetailDialog"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"

  appendTo="body"
  [breakpoints]="{'960px': '75vw', '640px': '95vw'}"
  [style]="{width: '50vw'}">

  <ng-template #header>
    <div class="flex items-center justify-between w-full">
      <div class="flex items-center gap-2">
        <i class="pi pi-info-circle text-primary-500"></i>
        <span class="font-semibold">Görev Detayları</span>
      </div>
    </div>
  </ng-template>

    <div class="rounded-lg border border-surface-200 dark:border-surface-700 overflow-hidden">
      <p-table
        #dt
        [value]="selectedTask?.Items ?? []"
        [tableStyle]="{'min-width': '20rem'}"
        styleClass="p-datatable-sm"
        [scrollable]="true"
        scrollHeight="400px"
        [paginator]="true"
        [rows]="25"
        (onFilter)="onFilter($event)"
        dataKey="Id"
        responsiveLayout="stack"
        [showCurrentPageReport]="true"
        currentPageReportTemplate="Toplam {totalRecords} kayıttan {first} - {last} arası gösteriliyor"
        [rowsPerPageOptions]="[10,25,50,100]"
        [paginatorDropdownAppendTo]="'body'"
        [globalFilterFields]="['ProviderSku', 'ShowingMessage']"
        stripedRows
        >
        <ng-template #caption>
          <div class="flex items-center justify-between">
            <div class="flex items-center">

              <p-inputgroup>
                <p-inputgroup-addon>
                  <i class="pi pi-search"></i>
                </p-inputgroup-addon>

                <input
                  pInputText type="text" #filter
                  (input)="dt.filterGlobal($any($event.target).value ?? '', 'contains')" placeholder="Arama..."
                  class="w-40 lg:w-auto"/>
              </p-inputgroup>

              @if (filtered) {
                <div>
                  <p-button
                    class="p-button-outlined ml-1 hidden lg:block  " [style]="{'text-wrap':'nowrap'}"  label="Filtreyi Kaldır"
                    icon="pi pi-filter-slash" (click)="dt.clear();filtered=false; filter.value =''"></p-button>
                  <p-button
                    class="p-button-outlined ml-1 lg:hidden inline-flex justify-center "
                    icon="pi pi-filter-slash" (click)="dt.clear();filtered=false; filter.value =''"></p-button>
                </div>
              }

            </div>
          </div>

        </ng-template>



        <ng-template #header>
          <tr>
            @if(selectedTask?.Identity !== 0) {
              <th >
                <div class="flex justify-center items-center">
                Ürün
                </div>
              </th>
            }
            <th>
              <div class="flex justify-center items-center gap-2">
                <span>Kod</span>
                <p-columnFilter type="text" field="ProviderSku" display="menu"></p-columnFilter>
              </div>
            </th>
            <th >
              <div class="flex justify-center items-center gap-2">
              <span>Durumu</span>
              <p-columnFilter
                field="MonitorStatus" matchMode="equals"  display="menu">
                <ng-template  pTemplate="filter" let-value let-filter="filterCallback">
                  <p-select
                    class="w-full"
                    [ngModel]="value" appendTo="body" [options]="monitorStatusConst"
                    (onChange)="filter($event.value)" optionLabel="header" optionValue="field"
                    [showClear]="false" placeholder="Seçim Yapılmadı">
                    <ng-template let-item #item>
                      <p-chip styleClass="pl-0 pr-4">
                    <span
                      [ngClass]="item.field | monitorStatusColor"
                      class="text-white rounded-full w-8 h-8 flex items-center justify-center">
                        {{ (item.field | monitorStatus)?.slice(0, 1) }}
                    </span>
                        <span class="ml-2 font-medium w-max flex whitespace-nowrap flex-nowrap pr-3">
                        {{ item.field | monitorStatus }}
                    </span>
                      </p-chip>
                    </ng-template>
                  </p-select>
                </ng-template>
              </p-columnFilter>
              </div>
            </th>
            <th>
              <div class="flex justify-center items-center gap-2">
                <span>Mesaj</span>
                <p-columnFilter type="text" field="ShowingMessage" display="menu"></p-columnFilter>
              </div>
            </th>
          </tr>
        </ng-template>

        <ng-template #body let-item>
          <tr class="hover:bg-surface-50 dark:hover:bg-surface-800 transition-colors">
            @if(selectedTask?.Identity !== 0) {
              <td>
                <span class="p-column-title font-bold">Ürün</span>
                @if(item.ItemGuid && item.ItemGuid.length > 0 && item.Identity!==0) {
                  <div class="flex justify-center items-center">
                  <p-button
                    label="Ürünü Göster"
                    (onClick)="getProviderProduct(item.ItemGuid)"
                   >
                  </p-button>
                  </div>
                }
              </td>
            }
            <td >

                <span class="p-column-title font-bold">Pazaryeri Kodu</span>
              <div class="flex justify-center items-center">
                {{item.ProviderSku}}
              </div>

            </td>
            <td>
              <span class="p-column-title font-bold">Durumu</span>

              <div class="flex justify-center items-center">
              <p-chip styleClass="pl-0 pr-4 dark:bg-surface-700">
                <span
                  [ngClass]="item.MonitorStatus | monitorStatusColor"
                  class="text-white cursor-pointer rounded-full w-8 h-8 flex items-center justify-center">
                  {{ (item.MonitorStatus | monitorStatus).slice(0, 1) }}
                </span>
                <span class="ml-2 font-medium w-max flex whitespace-nowrap flex-nowrap pr-3">
                        {{ item.MonitorStatus | monitorStatus }}
                    </span>
              </p-chip>
              </div>
            </td>
            <td class="text-center">
              <span class="p-column-title font-bold">Mesaj</span>
              <div class="flex justify-center items-center">
                {{item.ShowingMessage ?? '-'}}
              </div>
            </td>
          </tr>
        </ng-template>

        <ng-template #emptymessage>
          <tr>
            <td [colSpan]="14">
              <div class="col-span-12 flex flex-col justify-center items-center ">
                <div class="h-[150px] w-[150px] flex justify-center items-center">
                  <img src="assets/images/png/search-not-found.png" class="h-full w-auto" />
                </div>
                <div class="text-center">
                  <h4></h4>
                </div>
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>

    </div>

</p-dialog>
