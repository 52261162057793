import { Route } from '@angular/router';

export const salesRouters: Route[] = [
  {
    path: 'new-sales',
    title: 'Yeni Satışlar',
    loadComponent: () =>
      import('./new-sales/new-sales.component').then(
        (m) => m.NewSalesComponent
      ),
  },
  {
    path: 'shipped-sales',
    title: 'Kargolanan Satışlar',
    loadComponent: () =>
      import('./shipped-sales/shipped-sales.component').then(
        (m) => m.ShippedSalesComponent
      ),
  },
  {
    path: 'ready-sales',
    title: 'Hazırlanan Satışlar',
    loadComponent: () =>
      import('./ready-sales/ready-sales.component').then(
        (m) => m.ReadySalesComponent
      ),
  },
  {
    path: 'invoice-sales',
    title: 'Faturalanan Satışlar',
    loadComponent: () =>
      import('./invoice-sales/invoice-sales.component').then(
        (m) => m.InvoiceSalesComponent
      ),
  },
  {
    path: 'cancellation-refund-sales',
    title: 'İptal ve İade Satışlar',
    loadComponent: () =>
      import(
        './cancellation-refund-sales/cancellation-refund-sales.component'
      ).then((m) => m.CancellationRefundSalesComponent),
  },
  {
    path: 'all-sales',
    title: 'Tüm Satışlar',
    loadComponent: () =>
      import('./all-sales/all-sales/all-sales.component').then(
        (m) => m.AllSalesComponent
      ),
  },
  {
    path: 'order-transactions',
    title: 'Tüm Satışlar',
    loadComponent: () =>
      import(
        './all-sales/order-transactions/order-transactions.component'
      ).then((m) => m.OrderTransactionsComponent),
  },
  {
    path: 'transactions-sales',
    title: 'Tüm Satışlar',
    loadComponent: () =>
      import(
        './cancellation-refund-sales/cancellation-refund-sales.component'
      ).then((m) => m.CancellationRefundSalesComponent),
  },
  {
    path: 'detail/:Id',
    title: 'Satış Detayı',
    loadComponent: () =>
      import(
        '../../components/sales/detail/main/main-sales-detail.component'
      ).then((m) => m.MainSalesDetailComponent),
  },
];
