<p-dialog
        [(visible)]="menuVisible"
        [modal]="true"
        appendTo="body"
        [maximizable]="true"
        styleClass="menu-settings-dialog"
        [style]="{width: '90vw', maxWidth: '1400px'}"
        [contentStyle]="{padding: '0'}"
        [header]="'Menü Ayarları'"
        [showHeader]="true">

    <!-- Modern Dialog Content -->
    <div class="p-4">
        <!-- Custom Tab System -->
        <div class="mb-8">
            <!-- Tab Navigation -->
          <div class="inline-flex flex-col lg:flex-row p-1 rounded-xl lg:rounded-full bg-surface-100 dark:bg-surface-800 shadow-inner w-full justify-center">
            <button *ngFor="let tab of tabs; let i = index"
                    (click)="activeTab = i"
                    class="flex-1 flex items-center justify-center gap-1 sm:gap-2 px-2 sm:px-4 md:px-6 py-2 sm:py-2.5 rounded-full transition-all duration-200 mb-1 sm:mb-0 sm:mx-1"
                    [class]="activeTab === i ?
                'bg-surface-0 dark:bg-surface-700 text-primary shadow-sm' :
                'text-surface-600 dark:text-surface-400 hover:bg-surface-50 dark:hover:bg-surface-700/50'">
              <i [class]="'pi ' + tab.icon + ' text-base sm:text-lg'"></i>
              <span class="font-medium whitespace-nowrap text-sm sm:text-base">{{tab.label}}</span>
            </button>
          </div>
        </div>

        <!-- Tab Panels -->
        <div [ngSwitch]="activeTab">
            <!-- Tema Ayarları Panel -->
            <div *ngSwitchCase="0" class="animate-fadeIn">
                <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 py-2 px-4">
                    <!-- Ana Renkler -->
                    <div class="bg-surface-0/40 dark:bg-surface-800/40 backdrop-blur-sm rounded-2xl shadow-lg border border-surface-200/50 dark:border-surface-700/50 overflow-hidden transform transition-all duration-300 hover:shadow-xl hover:border-primary/20">
                        <div class="flex items-center justify-between p-4 bg-gradient-to-r from-surface-50/80 to-surface-100/80 dark:from-surface-900/80 dark:to-surface-800/80 backdrop-blur-sm">
                            <div class="flex items-center gap-3">
                                <div class="w-10 h-10 rounded-xl bg-primary/10 flex items-center justify-center shadow-inner">
                                    <i class="pi pi-palette text-primary text-xl"></i>
                                </div>
                                <div>
                                    <h3 class="font-semibold text-base text-surface-900 dark:text-surface-0">Ana Renk</h3>
                                    <p class="text-xs text-surface-600 dark:text-surface-400 mt-0.5">Uygulamanın ana rengini seçin</p>
                                </div>
                            </div>
                        </div>
                        <div class="p-4">
                            <div class="grid grid-cols-8 gap-2">
                                @for(primaryColor of primaryColors; track $index){
                                    @let ngClass = themeService.themeConfig.primaryColor === primaryColor.name ?
                                        'ring-2 ring-offset-2 ring-primary scale-110' : 'hover:scale-110 hover:shadow-lg';
                                    <div class="flex justify-center items-center">
                                        <button (click)="updatePrimaryColor(primaryColor.name)"
                                                class="w-8 h-8 rounded-xl transition-all duration-200 shadow-sm"
                                                [ngClass]="[ngClass, primaryColor.class]"
                                                [attr.title]="primaryColor.name">
                                        </button>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>

                    <!-- Yüzey Rengi -->
                    <div class="bg-surface-0/40 dark:bg-surface-800/40 backdrop-blur-sm rounded-2xl shadow-lg border border-surface-200/50 dark:border-surface-700/50 overflow-hidden transform transition-all duration-300 hover:shadow-xl hover:border-primary/20">
                        <div class="flex items-center justify-between p-4 bg-gradient-to-r from-surface-50/80 to-surface-100/80 dark:from-surface-900/80 dark:to-surface-800/80 backdrop-blur-sm">
                            <div class="flex items-center gap-3">
                                <div class="w-10 h-10 rounded-xl bg-primary/10 flex items-center justify-center shadow-inner">
                                    <i class="pi pi-stop-circle text-primary text-xl"></i>
                                </div>
                                <div>
                                    <h3 class="font-semibold text-base text-surface-900 dark:text-surface-0">Yüzey Rengi</h3>
                                    <p class="text-xs text-surface-600 dark:text-surface-400 mt-0.5">Arka plan rengini özelleştirin</p>
                                </div>
                            </div>
                        </div>
                        <div class="p-4">
                            <div class="grid grid-cols-8 gap-2">
                                @for(surfaceColor of surfaceColors; track $index){
                                    @let color = themeService.darkMode() ? themeService.themeConfig.surfaceDarkColor : themeService.themeConfig.surfaceLightColor;
                                    @let ngClass = surfaceColor.name === color ?
                                        'ring-2 ring-offset-2 ring-primary scale-110' : 'hover:scale-110 hover:shadow-lg';
                                    <div class="flex justify-center items-center">
                                        <button (click)="updateSurface(surfaceColor.name)"
                                                class="w-8 h-8 rounded-xl transition-all duration-200 shadow-sm"
                                                [ngClass]="[ngClass, surfaceColor.class]"
                                                [attr.title]="surfaceColor.name">
                                        </button>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>

                    <!-- Ana Tema -->
                    <div class="bg-surface-0/40 dark:bg-surface-800/40 backdrop-blur-sm rounded-2xl shadow-lg border border-surface-200/50 dark:border-surface-700/50 overflow-hidden transform transition-all duration-300 hover:shadow-xl hover:border-primary/20">
                        <div class="flex items-center justify-between p-4 bg-gradient-to-r from-surface-50/80 to-surface-100/80 dark:from-surface-900/80 dark:to-surface-800/80 backdrop-blur-sm">
                            <div class="flex items-center gap-3">
                                <div class="w-10 h-10 rounded-xl bg-primary/10 flex items-center justify-center shadow-inner">
                                    <i class="pi pi-desktop text-primary text-xl"></i>
                                </div>
                                <div>
                                    <h3 class="font-semibold text-base text-surface-900 dark:text-surface-0">Ana Tema</h3>
                                    <p class="text-xs text-surface-600 dark:text-surface-400 mt-0.5">Tema stilini seçin</p>
                                </div>
                            </div>
                        </div>
                        <div class="p-4">
                            <div class="grid grid-cols-2 gap-2">
                                @for(theme of themes; track $index){
                                    @let isActive = themeService.themeConfig.baseTheme === theme;
                                    <button (click)="updatePreset(theme)"
                                            class="group flex items-center justify-center p-3 rounded-xl transition-all duration-200 backdrop-blur-sm"
                                            [ngClass]="isActive ? 'bg-primary text-white shadow-lg scale-[1.02]' : 'bg-surface-0/60 dark:bg-surface-700/60 text-surface-700 dark:text-surface-200 hover:bg-surface-100 dark:hover:bg-surface-600 hover:scale-[1.02]'">
                                        <span class="font-medium text-sm">{{theme}}</span>
                                    </button>
                                }
                            </div>
                        </div>
                    </div>

                    <!-- Görünüm Modu -->
                    <div class="bg-surface-0/40 dark:bg-surface-800/40 backdrop-blur-sm rounded-2xl shadow-lg border border-surface-200/50 dark:border-surface-700/50 overflow-hidden transform transition-all duration-300 hover:shadow-xl hover:border-primary/20">
                        <div class="flex items-center justify-between p-4 bg-gradient-to-r from-surface-50/80 to-surface-100/80 dark:from-surface-900/80 dark:to-surface-800/80 backdrop-blur-sm">
                            <div class="flex items-center gap-3">
                                <div class="w-10 h-10 rounded-xl bg-primary/10 flex items-center justify-center shadow-inner">
                                    <i class="pi pi-moon text-primary text-xl"></i>
                                </div>
                                <div>
                                    <h3 class="font-semibold text-base text-surface-900 dark:text-surface-0">Görünüm Modu</h3>
                                    <p class="text-xs text-surface-600 dark:text-surface-400 mt-0.5">Aydınlık veya karanlık mod</p>
                                </div>
                            </div>
                        </div>
                        <div class="p-4">
                            <div class="flex items-center justify-between p-4 rounded-xl bg-surface-0/60 dark:bg-surface-900/60 backdrop-blur-sm">
                                <div>
                                    <h4 class="font-medium text-sm text-surface-900 dark:text-surface-0">Karanlık Mod</h4>
                                    <p class="text-xs text-surface-600 dark:text-surface-400">Düşük ışık için optimize</p>
                                </div>
                                <p-toggleswitch [(ngModel)]="themeService.themeConfig.darkMode"
                                              (onChange)="updateDarkMode($event)"
                                              styleClass="scale-90">
                                    <ng-template #handle let-checked="checked">
                                        <i [class]="checked ? 'pi pi-moon' : 'pi pi-sun'" class="text-xs"></i>
                                    </ng-template>
                                </p-toggleswitch>
                            </div>
                        </div>
                    </div>

                    <!-- Yazı Boyutu -->
                    <div class="bg-surface-0/40 dark:bg-surface-800/40 backdrop-blur-sm rounded-2xl shadow-lg border border-surface-200/50 dark:border-surface-700/50 overflow-hidden transform transition-all duration-300 hover:shadow-xl hover:border-primary/20">
                        <div class="flex items-center justify-between p-2 bg-gradient-to-r from-surface-50 to-surface-100 dark:from-surface-900 dark:to-surface-800">
                            <div class="flex items-center gap-1">
                                <div class="w-8 h-8 rounded-lg bg-primary/10 flex items-center justify-center shadow-inner">
                                    <i class="pi pi-pencil text-primary text-lg"></i>
                                </div>
                                <h3 class="font-semibold text-sm text-surface-900 dark:text-surface-0">Yazı Boyutu</h3>
                            </div>
                        </div>
                        <div class="p-2 space-y-1">
                            <div class="flex items-center justify-between bg-surface-0/40 dark:bg-surface-800/40 p-2 pb-4 rounded-lg">
                                <span class="text-sm font-medium text-surface-900 dark:text-surface-0">{{themeService.themeConfig.fontSize}}px</span>
                                <div class="flex items-center gap-1">
                                    <button class="w-6 h-6 flex items-center justify-center rounded-lg bg-surface-0 dark:bg-surface-800 hover:bg-surface-100 dark:hover:bg-surface-700 transition-colors text-surface-600 dark:text-surface-400"
                                            (click)="decreaseFontSize()">
                                        <i class="pi pi-minus"></i>
                                    </button>
                                    <button class="w-6 h-6 flex items-center justify-center rounded-lg bg-surface-0 dark:bg-surface-800 hover:bg-surface-100 dark:hover:bg-surface-700 transition-colors text-surface-600 dark:text-surface-400"
                                            (click)="increaseFontSize()">
                                        <i class="pi pi-plus"></i>
                                    </button>
                                </div>
                            </div>

                            <p-slider [(ngModel)]="themeService.themeConfig.fontSize"
                                      (onChange)="updateFontSize()"
                                      [min]="8"
                                      [max]="24"
                                      class="w-full">
                            </p-slider>
                        </div>
                    </div>

                    <!-- Boş Alan (Grid dengesini korumak için) -->
                    <div class="hidden lg:block"></div>
                </div>
            </div>

            <!-- Menü Düzenleme Panel -->
            <div *ngSwitchCase="1" class="animate-fadeIn">
                <div class="p-4">
                

                    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 gap-6">
                        @for (menuItem of mainsSidebarItems(); track $index) {
                            @if (menuItem.label !== 'Anasayfa' && menuItem.label !== 'Sık Kullanılanlar') {
                                <div class="bg-surface-0 dark:bg-surface-800 rounded-2xl shadow-lg border border-surface-200 dark:border-surface-700 overflow-hidden">
                                    <!-- Kart Başlığı -->
                                    <div class="flex items-center justify-between p-5 bg-gradient-to-r from-surface-50 to-surface-100 dark:from-surface-900 dark:to-surface-800">
                                        <div class="flex items-center gap-4">
                                            @if (menuItem.icon) {
                                                @if (menuItem.icon.includes('http')) {
                                                    <p-avatar [image]="menuItem.icon"
                                                             styleClass="mr-2"
                                                             class="dark:bg-surface-300"
                                                             size="normal"
                                                             shape="circle">
                                                    </p-avatar>
                                                } @else {
                                                    <div class="w-12 h-12 rounded-xl bg-primary/10 flex items-center justify-center shadow-inner">
                                                        <i [class]="menuItem.icon + ' text-primary text-2xl'"></i>
                                                    </div>
                                                }
                                            }
                                            <div>
                                                <h3 class="font-semibold text-lg text-surface-900 dark:text-surface-0">
                                                    {{menuItem.label}}
                                                </h3>
                                                @if (menuItem.items) {
                                                    <p class="text-surface-600 dark:text-surface-400 text-sm mt-1">
                                                        {{menuItem.items.length}} alt menü
                                                    </p>
                                                }
                                            </div>
                                        </div>

                                        @if (menuItem.items) {
                                            <div class="flex items-center gap-4 bg-surface-0/50 dark:bg-surface-900/50 p-2 rounded-lg backdrop-blur-sm">
                                                <span class="text-sm text-surface-600 dark:text-surface-400">Varsayılan Açık</span>
                                                <p-toggleswitch [(ngModel)]="menuItem.expanded"
                                                              (ngModelChange)="reloadMenu()"
                                                              styleClass="scale-90">
                                                    <ng-template #handle let-checked="checked">
                                                        <i [ngClass]="['!text-xs', 'pi', checked ? 'pi-check' : 'pi-times']"></i>
                                                    </ng-template>
                                                </p-toggleswitch>
                                            </div>
                                        }
                                    </div>

                                    <!-- Alt Menü Öğeleri -->
                                    @if (menuItem.items && menuItem.items.length > 0) {
                                        <div class="p-3 space-y-1 max-h-[500px] overflow-y-auto custom-scrollbar">
                                            @for (subItem of menuItem.items; track $index) {
                                                <div class="space-y-2">
                                                    <!-- Ana Alt Menü Öğesi -->
                                                    <div class="group flex items-center justify-between p-2 rounded-xl bg-surface-50/50 dark:bg-surface-900/50 hover:bg-surface-100 dark:hover:bg-surface-800 transition-all duration-200 border border-surface-200/50 dark:border-surface-700/50 hover:border-primary/20">
                                                        <div class="flex items-center gap-4 min-w-0">
                                                            @if (subItem.icon) {
                                                                @if (subItem.icon.includes('http')) {
                                                                    <p-avatar
                                                                            [image]="subItem.icon" styleClass="mr-2" class="dark:bg-surface-300" size="normal"
                                                                            shape="circle"></p-avatar>
                                                                } @else {
                                                                    <div class="w-10 h-10 rounded-xl bg-primary/5 group-hover:bg-primary/10 flex items-center justify-center shrink-0 transition-colors">
                                                                        <i [class]="subItem.icon + ' text-primary'"></i>
                                                                    </div>
                                                                }
                                                            }
                                                            <div>
                                                                <span class="font-medium text-surface-700 dark:text-surface-200 truncate">{{ subItem.label }}</span>
                                                                @if (subItem.items && subItem.items.length > 0) {
                                                                    <p class="text-xs text-surface-500 dark:text-surface-400 mt-1">{{ subItem.items.length }} alt menü</p>
                                                                }
                                                            </div>
                                                        </div>

                                                        @if (!subItem.items || (subItem.items && subItem.items.length === 0)) {
                                                            <div class="flex items-center gap-4 shrink-0">
                                                                <span class="text-sm text-surface-600 dark:text-surface-400">Sık Kullanılan</span>
                                                                <p-toggleswitch
                                                                        [(ngModel)]="subItem['favorite']"
                                                                        [trueValue]="true"
                                                                        [falseValue]="false"
                                                                        (onChange)="toggleFavorite(subItem)"
                                                                        styleClass="scale-90">
                                                                    <ng-template #handle let-checked="checked">
                                                                        <i [ngClass]="['!text-xs', 'pi', checked ? 'pi-check' : 'pi-times']"></i>
                                                                    </ng-template>
                                                                </p-toggleswitch>
                                                            </div>
                                                        }
                                                    </div>

                                                    <!-- İç İçe Alt Menü Öğeleri -->
                                                    @if (subItem.items && subItem.items.length > 0) {
                                                        <div class="ml-6 pl-4 border-l-2 border-surface-200 dark:border-surface-700 space-y-3">
                                                            @for (nestedItem of subItem.items; track $index) {
                                                                <div class="group flex items-center justify-between p-2 rounded-xl bg-surface-50/30 dark:bg-surface-900/30 hover:bg-surface-100 dark:hover:bg-surface-800 transition-all duration-200 border border-surface-200/30 dark:border-surface-700/30 hover:border-primary/20">
                                                                    <div class="flex items-center gap-4 min-w-0">
                                                                        @if (nestedItem.icon) {
                                                                            @if (nestedItem.icon.includes('http')) {
                                                                                <p-avatar
                                                                                        [image]="nestedItem.icon" styleClass="mr-2" class="dark:bg-surface-300" size="normal"
                                                                                        shape="circle"></p-avatar>
                                                                            } @else {
                                                                                <div class="w-8 h-8 rounded-lg bg-primary/5 group-hover:bg-primary/10 flex items-center justify-center shrink-0 transition-colors">
                                                                                  <i [class]="nestedItem.icon + ' text-primary text-sm'"></i></div>
                                                                                }

                                                                        }


                                                                        <span class="font-medium text-surface-700 dark:text-surface-200 truncate text-sm">{{ nestedItem.label }}</span>
                                                                    </div>

                                                                    <div class="flex items-center gap-4 shrink-0">
                                                                        <span class="text-sm text-surface-600 dark:text-surface-400">Sık Kullanılan</span>
                                                                        <p-toggleswitch
                                                                                [(ngModel)]="nestedItem['favorite']"
                                                                                [trueValue]="true"
                                                                                [falseValue]="false"
                                                                                (onChange)="toggleFavorite(nestedItem)"
                                                                                styleClass="scale-75">
                                                                            <ng-template #handle let-checked="checked">
                                                                                <i [ngClass]="['!text-xs', 'pi', checked ? 'pi-check' : 'pi-times']"></i>
                                                                            </ng-template>
                                                                        </p-toggleswitch>
                                                                    </div>
                                                                </div>

                                                            }
                                                </div>
                                            }
                                                </div>
                                                  }
                                        </div>
                                    }
                                </div>
                            }
                        }
                    </div>
                </div>
            </div>

            <!-- Menü Sıralaması Panel -->
            <div *ngSwitchCase="2" class="animate-fadeIn">
                <div class="p-4">
                   

                    <div class="grid grid-cols-1 lg:grid-cols-2 gap-6">
                        <!-- Ana Menü Sıralaması -->
                        <div class="bg-surface-0 dark:bg-surface-800 rounded-2xl shadow-lg border border-surface-200 dark:border-surface-700 overflow-hidden">
                            <div class="flex items-center justify-between p-5 bg-gradient-to-r from-surface-50 to-surface-100 dark:from-surface-900 dark:to-surface-800">
                                <div class="flex items-center gap-4">
                                    <div class="w-12 h-12 rounded-xl bg-primary/10 flex items-center justify-center shadow-inner">
                                        <i class="pi pi-list text-primary text-2xl"></i>
                                    </div>
                                    <div>
                                        <h3 class="font-semibold text-lg text-surface-900 dark:text-surface-0">Ana Menü Sıralaması</h3>
                                        <p class="text-surface-600 dark:text-surface-400 text-sm mt-1">Menülerin sırasını değiştirin</p>
                                    </div>
                                </div>
                            </div>

                            <div class="p-5" id="main-sidebar-items">
                                <p-orderList [value]="mainsSidebarItems()" (onReorder)="reloadMenu()" [listStyle]="{'width': '100%'}" styleClass="modern-orderlist w-full border-none" [style]="{'width': '100%'}">
                                    <ng-template let-item pTemplate="item">
                                        <div class="flex flex-1 items-center gap-3 py-2 bg-surface-50/50 dark:bg-surface-900/50 rounded-xl cursor-move hover:bg-surface-100 dark:hover:bg-surface-800 transition-colors border border-surface-200/50 dark:border-surface-700/50 hover:border-primary/20">
                                            <i class="pi pi-grip-vertical text-surface-400"></i>
                                            @if (item.icon) {
                                                @if (item.icon.includes('http')) {
                                                    <p-avatar
                                                            [image]="item.icon" styleClass="mr-2" class="dark:bg-surface-300" size="normal"
                                                            shape="circle"></p-avatar>
                                                } @else {
                                                    <div class="w-10 h-10 rounded-xl bg-primary/5 flex items-center justify-center">
                                                        <i [class]="item.icon + ' text-primary text-lg'"></i>
                                                    </div>
                                                }
                                            }
                                            <span class="font-medium">{{ item.label }}</span>
                                        </div>
                                    </ng-template>
                                </p-orderList>
                            </div>
                        </div>

                        <!-- Sık Kullanılanlar Sıralaması -->
                        <div class="bg-surface-0 dark:bg-surface-800 rounded-2xl shadow-lg border border-surface-200 dark:border-surface-700 overflow-hidden">
                            <div class="flex items-center justify-between p-5 bg-gradient-to-r from-yellow-50 to-surface-100 dark:from-surface-900 dark:to-surface-800">
                                <div class="flex items-center gap-4">
                                    <div class="w-12 h-12 rounded-xl bg-yellow-500/10 flex items-center justify-center shadow-inner">
                                        <i class="pi pi-star-fill text-yellow-500 text-2xl"></i>
                                    </div>
                                    <div>
                                        <h3 class="font-semibold text-lg text-surface-900 dark:text-surface-0">Sık Kullanılanlar</h3>
                                        <p class="text-surface-600 dark:text-surface-400 text-sm mt-1">Sürükle & bırak ile sıralayın</p>
                                    </div>
                                </div>
                            </div>

                            <div class="p-5" id="favorite-sidebar-items">
                                <p-orderList [value]="favoriteSidebarItems()" (onReorder)="reloadMenu()" [listStyle]="{'width': '100%'}" styleClass="modern-orderlist w-full border-none" [style]="{'width': '100%'}">
                                    <ng-template let-item pTemplate="item">
                                        <div class="flex flex-1 items-center gap-3 py-2 bg-surface-50/50 dark:bg-surface-900/50 rounded-xl cursor-move hover:bg-surface-100 dark:hover:bg-surface-800 transition-colors border border-surface-200/50 dark:border-surface-700/50 hover:border-primary/20">
                                            <i class="pi pi-grip-vertical text-surface-400"></i>
                                            @if (item.icon) {
                                                @if (item.icon.includes('http')) {
                                                    <p-avatar
                                                            [image]="item.icon" styleClass="mr-2" class="dark:bg-surface-300" size="normal"
                                                            shape="circle"></p-avatar>
                                                } @else {
                                                    <div class="w-10 h-10 rounded-xl bg-primary/5 flex items-center justify-center">
                                                        <i [class]="item.icon + ' text-primary text-lg'"></i>
                                                    </div>
                                                }
                                            }
                                            <span class="font-medium">{{ item.label }}</span>
                                        </div>
                                    </ng-template>
                                </p-orderList>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <ng-template #footer>
        <div class="flex w-full justify-end gap-4 p-4 pb-0 border-t border-surface-200 dark:border-surface-700 ">
            <p-button
                    [text]="true"
                    label="İptal"
                    (click)="closeMenu()">
            </p-button>
            <p-button
                    label="Değişiklikleri Kaydet"
                    icon="pi pi-check"
                    (click)="saveMenuSettings()">
            </p-button>
        </div>
    </ng-template>
</p-dialog>
