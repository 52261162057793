import { Route } from '@angular/router';

export const notificationsRouters: Route[] = [
  {
    path: '',
    pathMatch: 'full',
    title: 'Bildirimler',
    loadComponent: () =>
      import('./base/base-notifications.component').then(
        (m) => m.BaseNotificationsComponent
      ),
  },
];
