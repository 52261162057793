import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { catchError, throwError } from 'rxjs';
import { AuthService } from '../../services/auth/auth.service';
import { environment } from '../../../../environments/environment';

export const jwtInterceptor: HttpInterceptorFn = (req, next) => {
  const authService = inject(AuthService);
  const token = authService.token$();
  const startWithBaseUrl = req.url.startsWith(environment.baseUrl);
  if (token && startWithBaseUrl) {
    req = req.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
  return next(req).pipe(
    catchError((err) => {
      /*|| err.status === 0*/
      if (err.status === 401) {
        authService.logout(true);
      }

      return throwError(err);
    })
  );
};
